.athx-dropdown {
  position: relative;
  display: inline-block;


  &:has(.select), &:has(select){
    select {
      display: none;
    }
  }

  &:has(.athx-dropdown-menu) {
    .athx-dropdown-toggle::after {
      // rotate: 90deg
      transform: rotate(180deg);
    }

  }

}

.athx-dropdown-toggle {
  cursor: pointer;
  position: relative;
  padding-right: 1rem !important;

  &.toggle {
    &::after {
      content: "";
      transition: all .1s ease-in-out;
      -webkit-transition: all .1s ease-in-out;
      position: absolute;
      top: calc(50% - 9px);
      right: 0;
      width: 18px;
      height: 18px;
      transform: rotate(90deg);
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCIgd2lkdGg9IjE4cHgiIGhlaWdodD0iMThweCI+CiAgPHBhdGggZmlsbD0iIzQ5NGM0ZSIgZD0iTTEzLjcgMTAuMjlsLTQtNGExLjAxMiAxLjAxMiAwIDAgMC0xLjQxOCAwbC00IDRBMSAxIDAgMCAwIDUuMDA3IDEySDEzYTEgMSAwIDAgMCAuNy0xLjcxeiIvPgo8L3N2Zz4=');
    }
  }

}

.athx-dropdown-menu {
  --bg: white;
  --color: black;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--bg);
  color: var(--color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 10;

  &.select {
    max-height: 300px;
    overflow-y: auto;
  }

  &.primary {
    --bg: var(--primary);
    --color: var(--primary-text);
  }


  &.secondary {
    --bg: var(--secondary);
    --color: var(--secondary-text);
  }

  &.interactive {
    --bg: var(--interactive);
    --color: var(--interactive-text);
  }

  &.accent {
    --bg: var(--accent);
    --color: var(--accent-text);
  }

  &.error {
    --bg: var(--error);
    --color: var(--error-text);
  }

  &.warning {
    --bg: var(--warning);
    --color: var(--warning-text);
  }

  &.success {
    --bg: var(--success);
    --color: var(--success-text);
  }

  &.info {
    --bg: var(--info);
    --color: var(--info-text);
  }





}

.athx-dropdown-item {

  --athx-dropdown-item-before-theme: var(--accent);
  --athx-dropdown-item-before-text-theme: var(--accent-text);


  &.secondary {
    --athx-dropdown-item-before-theme: var(--secondary);
    --athx-dropdown-item-before-text-theme: var(--secondary-text);
  }

  &.interactive {
    --athx-dropdown-item-before-theme: var(--interactive);
    --athx-dropdown-item-before-text-theme: var(--interactive-text);
  }

  &.accent {
    --athx-dropdown-item-before-theme: var(--accent);
    --athx-dropdown-item-before-text-theme: var(--accent-text);
  }

  &.error {
    --athx-dropdown-item-before-theme: var(--error);
    --athx-dropdown-item-before-text-theme: var(--error-text);
  }

  &.warning {
    --athx-dropdown-item-before-theme: var(--warning);
    --athx-dropdown-item-before-text-theme: var(--warning-text);
  }

  &.success {
    --athx-dropdown-item-before-theme: var(--success);
    --athx-dropdown-item-before-text-theme: var(--success-text);
  }

  &.info {
    --athx-dropdown-item-before-theme: var(--info);
    --athx-dropdown-item-before-text-theme: var(--info-text);
  }

  padding: .5rem .5rem;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-in-out;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid rgba(silver, .5);

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 0%;
    height: 3px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    opacity: .5;
    z-index: 10;
  }

  &:hover {
    &::after {
      width: 100%;
      left: 0;
      background-color: var(--athx-dropdown-item-before-theme) !important;
    }

  }

  &:active {
    transition: none;
    background-color: var(--athx-dropdown-item-before-theme);
    color: var(--athx-dropdown-item-before-text-theme);
  }

  &>svg,
  &>img {
    margin-right: .25rem;
  }

  &>img {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }


}