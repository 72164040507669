.athx-window {
  .athx-window-body {
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: calc(100% - 30px);
    flex: 1 1 auto;
    border-bottom-left-radius: var(--athx-body-border-radius);
    border-bottom-right-radius: var(--athx-body-border-radius);
    border: 1px solid var(--athx-window-border-color);

    .loading-overlay {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &>span {
        padding: 1rem;
        border-radius: 1rem;
        background-color: white;
        color: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--interactive);

        &:not(svg) {
          font-size: 2rem;
        }
      }

      &.error>span {
        color: var(--error);
      }

    }

    .athx-window-resizer {
      width: .25rem;
      height: 100%;

      &.l {
        cursor: ew-resize;
        top: 0px;
        left: 0px;
      }

      &.r {
        cursor: ew-resize;
        top: 0px;
        right: 0px;
      }
    }


    .athx-window-content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0px;
      border-bottom-left-radius: var(--athx-body-border-radius);
      border-bottom-right-radius: var(--athx-body-border-radius);

      .athx-window-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1 1 auto;
        overflow-x: hidden;
        position: relative;
        padding: 0rem;
        background-color: var(--athx-window-body-background-color);
        color: var(--athx-window-body-color);
        border-bottom-left-radius: var(--athx-body-border-radius);
        border-bottom-right-radius: var(--athx-body-border-radius);

        .athx-window-content-adapter {
          width: 100%;
          height: 100%;
          max-height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          padding: .5rem;
          padding-bottom: 1rem;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--accent);
            border-radius: 2px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: var(--accent-light);
          }

          &::-webkit-scrollbar-track {
            background-color: var(--accent-dark);
          }
        }


      }
    }
  }
}