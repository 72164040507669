html[athx-theme=default]:root,
:root {
  --primary: #262427;
  --primary-light: #343235;
  --primary-dark: #1b191b;
  --primary-text: #ffffff;
  --primary-light-text: #ffffff;
  --primary-dark-text: #ffffff;
  --primary-rgb: 38, 36, 39;
  --primary-rgb-light: 52, 50, 53;
  --primary-rgb-dark: 27, 25, 27;
  --primary-rgb-text: 255, 255, 255;
  --primary-rgb-light-text: 255, 255, 255;
  --primary-rgb-dark-text: 255, 255, 255;

  --accent: #D4AF37;
  --accent-light: #FFD700;
  --accent-dark: #B8860B;
  --accent-text: #000000;
  --accent-light-text: #000000;
  --accent-dark-text: #000000;
  --accent-rgb: 212, 175, 55;
  --accent-rgb-light: 255, 215, 0;
  --accent-rgb-dark: 184, 134, 11;
  --accent-rgb-text: 0, 0, 0;
  --accent-rgb-light-text: 0, 0, 0;
  --accent-rgb-dark-text: 0, 0, 0;


  --secondary: #2E2E2E;
  --secondary-light: #555555;
  --secondary-dark: #1A1A1A;
  --secondary-text: #ffffff;
  --secondary-light-text: #ffffff;
  --secondary-dark-text: #ffffff;
  --secondary-rgb: 46, 46, 46;
  --secondary-rgb-light: 85, 85, 85;
  --secondary-rgb-dark: 26, 26, 26;
  --secondary-rgb-text: 255, 255, 255;
  --secondary-rgb-light-text: 255, 255, 255;
  --secondary-rgb-dark-text: 255, 255, 255;



  --background: #f2f2f2;
  --background-light: #ffffff;
  --background-dark: #e0e0e0;
  --background-text: #000000;
  --background-light-text: #000000;
  --background-dark-text: #000000;
  --background-rgb: 242, 242, 242;
  --background-rgb-light: 255, 255, 255;
  --background-rgb-dark: 224, 224, 224;
  --background-rgb-text: 0, 0, 0;
  --background-rgb-light-text: 0, 0, 0;
  --background-rgb-dark-text: 0, 0, 0;




  --interactive: #3A75C4;
  --interactive-light: #6699FF;
  --interactive-dark: #204A83;
  --interactive-text: #FFFFFF;
  --interactive-light-text: #ffffff;
  --interactive-dark-text: #FFFFFF;
  --interactive-rgb: 58, 117, 196;
  --interactive-rgb-light: 102, 153, 255;
  --interactive-rgb-dark: 32, 74, 131;
  --interactive-rgb-text: 255, 255, 255;
  --interactive-rgb-light-text: 255, 255, 255;
  --interactive-rgb-dark-text: 255, 255, 255;


  --error: #B22222;
  --error-light: #E60000;
  --error-dark: #7F0000;
  --error-text: #FFFFFF;
  --error-light-text: #FFFFFF;
  --error-dark-text: #FFFFFF;
  --error-rgb: 178, 34, 34;
  --error-rgb-light: 230, 0, 0;
  --error-rgb-dark: 127, 0, 0;
  --error-rgb-text: 255, 255, 255;
  --error-rgb-light-text: 255, 255, 255;
  --error-rgb-dark-text: 255, 255, 255;



  --info: #17A2B8;
  --info-light: #63D1E0;
  --info-dark: #0D697A;
  --info-text: #ffffff;
  --info-light-text: #ffffff;
  --info-dark-text: #ffffff;
  --info-rgb: 23, 162, 184;
  --info-rgb-light: 99, 209, 224;
  --info-rgb-dark: 13, 105, 122;
  --info-rgb-text: 255, 255, 255;
  --info-rgb-light-text: 255, 255, 255;
  ;
  --info-rgb-dark-text: 255, 255, 255;




  --warning: #FFC107;
  --warning-light: #FFD54F;
  --warning-dark: #C79100;
  --warning-text: #000000;
  --warning-light-text: #000000;
  --warning-dark-text: #000000;
  --warning-rgb: 255, 193, 7;
  --warning-rgb-light: 255, 213, 79;
  --warning-rgb-dark: 199, 145, 0;
  --warning-rgb-text: 0, 0, 0;
  --warning-rgb-light-text: 0, 0, 0;
  --warning-rgb-dark-text: 0, 0, 0;



  --success: #238d3c;
  --success-light: #2caa49;
  --success-dark: #1e7a33;
  --success-text: #FFFFFF;
  --success-light-text: #ffffff;
  --success-dark-text: #FFFFFF;
  --success-rgb: 35, 141, 60;
  --success-rgb-light: 44, 170, 73;
  --success-rgb-dark: 30, 122, 51;
  --success-rgb-text: 255, 255, 255;
  --success-rgb-light-text: 255, 255, 255;
  --success-rgb-dark-text: 255, 255, 255;



  --athx-window-body-background-color: #2c2c2c;
  --athx-window-body-background-color-accent: #585656;
  --athx-window-body-color: #ffffff;




}

html[athx-theme=attractive]:root {
  --primary: #262427;
  --primary-light: #343235;
  --primary-dark: #1b191b;
  --primary-text: #ffffff;
  --primary-light-text: #ffffff;
  --primary-dark-text: #ffffff;
  --primary-rgb: 38, 36, 39;
  --primary-rgb-light: 52, 50, 53;
  --primary-rgb-dark: 27, 25, 27;
  --primary-rgb-text: 255, 255, 255;
  --primary-rgb-light-text: 255, 255, 255;
  --primary-rgb-dark-text: 255, 255, 255;

  --accent: #7823c7;
  --accent-light: #9343dd;
  --accent-dark: #571297;
  --accent-text: #ffffff;
  --accent-light-text: #ffffff;
  --accent-dark-text: #ffffff;
  --accent-rgb: 120, 35, 199;
  --accent-rgb-light: 147, 67, 221;
  --accent-rgb-dark: 87, 18, 151;
  --accent-rgb-text: 255, 255, 255;
  --accent-rgb-light-text: 255, 255, 255;
  --accent-rgb-dark-text: 255, 255, 255;


  --secondary: #2E2E2E;
  --secondary-light: #555555;
  --secondary-dark: #1A1A1A;
  --secondary-text: #ffffff;
  --secondary-light-text: #ffffff;
  --secondary-dark-text: #ffffff;
  --secondary-rgb: 46, 46, 46;
  --secondary-rgb-light: 85, 85, 85;
  --secondary-rgb-dark: 26, 26, 26;
  --secondary-rgb-text: 255, 255, 255;
  --secondary-rgb-light-text: 255, 255, 255;
  --secondary-rgb-dark-text: 255, 255, 255;



  --background: #f2f2f2;
  --background-light: #ffffff;
  --background-dark: #e0e0e0;
  --background-text: #000000;
  --background-light-text: #000000;
  --background-dark-text: #000000;
  --background-rgb: 242, 242, 242;
  --background-rgb-light: 255, 255, 255;
  --background-rgb-dark: 224, 224, 224;
  --background-rgb-text: 0, 0, 0;
  --background-rgb-light-text: 0, 0, 0;
  --background-rgb-dark-text: 0, 0, 0;




  --interactive: #00FFD1;
  --interactive-light: #85fde7;
  --interactive-dark: #00b896;
  --interactive-text: #000000;
  --interactive-light-text: #000000;
  --interactive-dark-text: #FFFFFF;
  --interactive-rgb: 0, 255, 209;
  --interactive-rgb-light: 133, 253, 231;
  --interactive-rgb-dark: 0, 184, 150;
  --interactive-rgb-text: 0, 0, 0;
  --interactive-rgb-light-text: 0, 0, 0;
  --interactive-rgb-dark-text: 255, 255, 255;


  --error: #B22222;
  --error-light: #E60000;
  --error-dark: #7F0000;
  --error-text: #FFFFFF;
  --error-light-text: #FFFFFF;
  --error-dark-text: #FFFFFF;
  --error-rgb: 178, 34, 34;
  --error-rgb-light: 230, 0, 0;
  --error-rgb-dark: 127, 0, 0;
  --error-rgb-text: 255, 255, 255;
  --error-rgb-light-text: 255, 255, 255;
  --error-rgb-dark-text: 255, 255, 255;



  --info: #17A2B8;
  --info-light: #63D1E0;
  --info-dark: #0D697A;
  --info-text: #ffffff;
  --info-light-text: #ffffff;
  --info-dark-text: #ffffff;
  --info-rgb: 23, 162, 184;
  --info-rgb-light: 99, 209, 224;
  --info-rgb-dark: 13, 105, 122;
  --info-rgb-text: 255, 255, 255;
  --info-rgb-light-text: 255, 255, 255;
  ;
  --info-rgb-dark-text: 255, 255, 255;




  --warning: #FFC107;
  --warning-light: #FFD54F;
  --warning-dark: #C79100;
  --warning-text: #000000;
  --warning-light-text: #000000;
  --warning-dark-text: #000000;
  --warning-rgb: 255, 193, 7;
  --warning-rgb-light: 255, 213, 79;
  --warning-rgb-dark: 199, 145, 0;
  --warning-rgb-text: 0, 0, 0;
  --warning-rgb-light-text: 0, 0, 0;
  --warning-rgb-dark-text: 0, 0, 0;



  --success: #238d3c;
  --success-light: #2caa49;
  --success-dark: #1e7a33;
  --success-text: #FFFFFF;
  --success-light-text: #ffffff;
  --success-dark-text: #FFFFFF;
  --success-rgb: 35, 141, 60;
  --success-rgb-light: 44, 170, 73;
  --success-rgb-dark: 30, 122, 51;
  --success-rgb-text: 255, 255, 255;
  --success-rgb-light-text: 255, 255, 255;
  --success-rgb-dark-text: 255, 255, 255;



  --athx-window-body-background-color: #2c2c2c;
  --athx-window-body-background-color-accent: #585656;
  --athx-window-body-color: #ffffff;




}

html[athx-theme=energy]:root {

  --primary: #D9D9D9;
  --primary-light: #E6E6E6;
  --primary-dark: #A3A3A3;
  --primary-text: #1A1A1A;
  --primary-light-text: #1A1A1A;
  --primary-dark-text: #1A1A1A;
  --primary-rgb: 217, 217, 217;
  --primary-rgb-light: 230, 230, 230;
  --primary-rgb-dark: 163, 163, 163;
  --primary-rgb-text: 26, 26, 26;

  --accent: #FF5733;
  --accent-light: #FF7F66;
  --accent-dark: #CC4629;
  --accent-text: #000000;
  --accent-light-text: #000000;
  --accent-dark-text: #000000;
  --accent-rgb: 255, 87, 51;
  --accent-rgb-light: 255, 127, 102;
  --accent-rgb-dark: 204, 70, 41;
  --accent-rgb-text: 0, 0, 0;

  --secondary: #F2F2F2;
  --secondary-light: #FAFAFA;
  --secondary-dark: #D0D0D0;
  --secondary-text: #1A1A1A;
  --secondary-light-text: #1A1A1A;
  --secondary-dark-text: #1A1A1A;
  --secondary-rgb: 242, 242, 242;
  --secondary-rgb-light: 250, 250, 250;
  --secondary-rgb-dark: 208, 208, 208;
  --secondary-rgb-text: 26, 26, 26;

  --background: #E0E0E0;
  --background-light: #F0F0F0;
  --background-dark: #BEBEBE;
  --background-text: #1A1A1A;
  --background-light-text: #1A1A1A;
  --background-dark-text: #1A1A1A;

  --interactive: #4CAF50;
  --interactive-light: #6FD67E;
  --interactive-dark: #388E3C;
  --interactive-text: #FFFFFF;
  --interactive-light-text: #ffffff;
  --interactive-dark-text: #FFFFFF;
  --interactive-rgb: 76, 175, 80;
  --interactive-rgb-light: 111, 214, 126;
  --interactive-rgb-dark: 56, 142, 60;
  --interactive-rgb-text: 255, 255, 255;

  --error: #FF4444;
  --error-light: #FF6666;
  --error-dark: #B20000;
  --error-text: #FFFFFF;
  --error-light-text: #FFFFFF;
  --error-dark-text: #FFFFFF;

  --info: #2196F3;
  --info-light: #64B5F6;
  --info-dark: #1976D2;
  --info-text: #FFFFFF;
  --info-light-text: #FFFFFF;
  --info-dark-text: #FFFFFF;

  --warning: #FFC107;
  --warning-light: #FFD54F;
  --warning-dark: #C79100;
  --warning-text: #000000;
  --warning-light-text: #000000;
  --warning-dark-text: #000000;

  --success: #2f7933;
  --success-light: #3a8f3e;
  --success-dark: #28662b;
  --success-text: #FFFFFF;
  --success-light-text: #ffffff;
  --success-dark-text: #FFFFFF;








  --athx-window-body-background-color: #ffffff;
  --athx-window-body-background-color-accent: #585656;
  --athx-window-body-color: #000000;

}

html[athx-theme=vibe]:root {
  --primary: #2E4053;
  --primary-light: #34495E;
  --primary-dark: #212F3C;
  --primary-text: #FFFFFF;
  --primary-light-text: #FFFFFF;
  --primary-dark-text: #FFFFFF;
  --primary-rgb: 46, 64, 83;
  --primary-rgb-light: 52, 73, 94;
  --primary-rgb-dark: 33, 47, 60;
  --primary-rgb-text: 255, 255, 255;

  --accent: #1ABC9C;
  --accent-light: #48C9B0;
  --accent-dark: #16A085;
  --accent-text: #FFFFFF;
  --accent-light-text: #000000;
  --accent-dark-text: #FFFFFF;
  --accent-rgb: 26, 188, 156;
  --accent-rgb-light: 72, 201, 176;
  --accent-rgb-dark: 22, 160, 133;
  --accent-rgb-text: 255, 255, 255;

  --secondary: #566573;
  --secondary-light: #808B96;
  --secondary-dark: #2C3E50;
  --secondary-text: #FFFFFF;
  --secondary-light-text: #FFFFFF;
  --secondary-dark-text: #FFFFFF;
  --secondary-rgb: 86, 101, 115;
  --secondary-rgb-light: 128, 139, 150;
  --secondary-rgb-dark: 44, 62, 80;
  --secondary-rgb-text: 255, 255, 255;

  --background: #ECF0F1;
  --background-light: #FFFFFF;
  --background-dark: #BDC3C7;
  --background-text: #1A1A1A;
  --background-light-text: #1A1A1A;
  --background-dark-text: #1A1A1A;

  --interactive: #FF5733;
  --interactive-light: #FF7F66;
  --interactive-dark: #CC4629;
  --interactive-text: #FFFFFF;
  --interactive-light-text: #000000;
  --interactive-dark-text: #FFFFFF;
  --interactive-rgb: 52, 152, 219;
  --interactive-rgb-light: 93, 173, 226;
  --interactive-rgb-dark: 46, 134, 193;
  --interactive-rgb-text: 255, 255, 255;

  --error: #E74C3C;
  --error-light: #F1948A;
  --error-dark: #C0392B;
  --error-text: #FFFFFF;
  --error-light-text: #FFFFFF;
  --error-dark-text: #FFFFFF;

  --info: #2980B9;
  --info-light: #85C1E9;
  --info-dark: #1F618D;
  --info-text: #FFFFFF;
  --info-light-text: #000000;
  --info-dark-text: #FFFFFF;

  --warning: #F39C12;
  --warning-light: #F7DC6F;
  --warning-dark: #E67E22;
  --warning-text: #000000;
  --warning-light-text: #000000;
  --warning-dark-text: #FFFFFF;

  --success: #27AE60;
  --success-light: #58D68D;
  --success-dark: #1E8449;
  --success-text: #FFFFFF;
  --success-light-text: #000000;
  --success-dark-text: #FFFFFF;
}

html[athx-theme=classic]:root {
  --primary: #2C3E50;
  --primary-light: #34495E;
  --primary-dark: #1C2833;
  --primary-text: #FFFFFF;
  --primary-light-text: #FFFFFF;
  --primary-dark-text: #FFFFFF;
  --primary-rgb: 44, 62, 80;
  --primary-rgb-light: 52, 73, 94;
  --primary-rgb-dark: 28, 40, 51;
  --primary-rgb-text: 255, 255, 255;
  --primary-rgb-light-text: 255, 255, 255;
  --primary-rgb-dark-text: 255, 255, 255;

  --accent: #7F8C8D;
  --accent-light: #BDC3C7;
  --accent-dark: #4D5656;
  --accent-text: #000000;
  --accent-light-text: #000000;
  --accent-dark-text: #FFFFFF;
  --accent-rgb: 127, 140, 141;
  --accent-rgb-light: 189, 195, 199;
  --accent-rgb-dark: 77, 86, 86;
  --accent-rgb-text: 0, 0, 0;
  --accent-rgb-light-text: 0, 0, 0;
  --accent-rgb-dark-text: 255, 255, 255;

  --secondary: #95A5A6;
  --secondary-light: #D0D6D7;
  --secondary-dark: #707B7C;
  --secondary-text: #000000;
  --secondary-light-text: #000000;
  --secondary-dark-text: #FFFFFF;
  --secondary-rgb: 149, 165, 166;
  --secondary-rgb-light: 208, 214, 215;
  --secondary-rgb-dark: 112, 123, 124;
  --secondary-rgb-text: 0, 0, 0;
  --secondary-rgb-light-text: 0, 0, 0;
  --secondary-rgb-dark-text: 255, 255, 255;

  --background: #ECF0F1;
  --background-light: #FFFFFF;
  --background-dark: #D5DBDB;
  --background-text: #1A1A1A;
  --background-light-text: #1A1A1A;
  --background-dark-text: #1A1A1A;

  --interactive: #6496c7;
  --interactive-light: #85b6e7;
  --interactive-dark: #3f79b3;
  --interactive-text: #000000;
  --interactive-light-text: #000000;
  --interactive-dark-text: #FFFFFF;
  --interactive-rgb: 100, 150, 199;
  --interactive-rgb-light: 133, 182, 231;
  --interactive-rgb-dark: 63, 121, 179;
  --interactive-rgb-text: 0, 0, 0;
  --interactive-rgb-light-text: 0, 0, 0;
  --interactive-rgb-dark-text: 255, 255, 255;

  --error: #C0392B;
  --error-light: #E74C3C;
  --error-dark: #A93226;
  --error-text: #FFFFFF;
  --error-light-text: #FFFFFF;
  --error-dark-text: #FFFFFF;
  --error-rgb: 192, 57, 43;
  --error-rgb-light: 231, 76, 60;
  --error-rgb-dark: 169, 50, 38;
  --error-rgb-text: 255, 255, 255;
  --error-rgb-light-text: 255, 255, 255;
  --error-rgb-dark-text: 255, 255, 255;

  --info: #2980B9;
  --info-light: #85C1E9;
  --info-dark: #1F618D;
  --info-text: #FFFFFF;
  --info-light-text: #000000;
  --info-dark-text: #FFFFFF;
  --info-rgb: 41, 128, 185;
  --info-rgb-light: 133, 193, 233;
  --info-rgb-dark: 31, 97, 141;
  --info-rgb-text: 255, 255, 255;
  --info-rgb-light-text: 0, 0, 0;
  --info-rgb-dark-text: 255, 255, 255;

  --warning: #E67E22;
  --warning-light: #F39C12;
  --warning-dark: #D35400;
  --warning-text: #000000;
  --warning-light-text: #FFFFFF;
  --warning-dark-text: #000000;
  --warning-rgb: 230, 126, 34;
  --warning-rgb-light: 243, 156, 18;
  --warning-rgb-dark: 211, 84, 0;
  --warning-rgb-text: 0, 0, 0;
  --warning-rgb-light-text: 255, 255, 255;
  --warning-rgb-dark-text: 0, 0, 0;

  --success: #27AE60;
  --success-light: #58D68D;
  --success-dark: #1E8449;
  --success-text: #FFFFFF;
  --success-light-text: #000000;
  --success-dark-text: #FFFFFF;
  --success-rgb: 39, 174, 96;
  --success-rgb-light: 88, 214, 141;
  --success-rgb-dark: 30, 132, 73;
  --success-rgb-text: 255, 255, 255;
  --success-rgb-light-text: 0, 0, 0;
  --success-rgb-dark-text: 255, 255, 255;

  *,
  *::before,
  *::after {
    border-radius: 0 !important;
  }
}

.w-0 {
  width: 0% !important;
}

.w-1 {
  width: 1% !important;
}

.w-2 {
  width: 2% !important;
}

.w-3 {
  width: 3% !important;
}

.w-4 {
  width: 4% !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-11 {
  width: 11% !important;
}

.w-12 {
  width: 12% !important;
}

.w-13 {
  width: 13% !important;
}

.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}

.w-16 {
  width: 16% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-21 {
  width: 21% !important;
}

.w-22 {
  width: 22% !important;
}

.w-23 {
  width: 23% !important;
}

.w-24 {
  width: 24% !important;
}

.w-25 {
  width: 25% !important;
}

.w-26 {
  width: 26% !important;
}

.w-27 {
  width: 27% !important;
}

.w-28 {
  width: 28% !important;
}

.w-29 {
  width: 29% !important;
}

.w-30 {
  width: 30% !important;
}

.w-31 {
  width: 31% !important;
}

.w-32 {
  width: 32% !important;
}

.w-33 {
  width: 33% !important;
}

.w-34 {
  width: 34% !important;
}

.w-35 {
  width: 35% !important;
}

.w-36 {
  width: 36% !important;
}

.w-37 {
  width: 37% !important;
}

.w-38 {
  width: 38% !important;
}

.w-39 {
  width: 39% !important;
}

.w-40 {
  width: 40% !important;
}

.w-41 {
  width: 41% !important;
}

.w-42 {
  width: 42% !important;
}

.w-43 {
  width: 43% !important;
}

.w-44 {
  width: 44% !important;
}

.w-45 {
  width: 45% !important;
}

.w-46 {
  width: 46% !important;
}

.w-47 {
  width: 47% !important;
}

.w-48 {
  width: 48% !important;
}

.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.w-51 {
  width: 51% !important;
}

.w-52 {
  width: 52% !important;
}

.w-53 {
  width: 53% !important;
}

.w-54 {
  width: 54% !important;
}

.w-55 {
  width: 55% !important;
}

.w-56 {
  width: 56% !important;
}

.w-57 {
  width: 57% !important;
}

.w-58 {
  width: 58% !important;
}

.w-59 {
  width: 59% !important;
}

.w-60 {
  width: 60% !important;
}

.w-61 {
  width: 61% !important;
}

.w-62 {
  width: 62% !important;
}

.w-63 {
  width: 63% !important;
}

.w-64 {
  width: 64% !important;
}

.w-65 {
  width: 65% !important;
}

.w-66 {
  width: 66% !important;
}

.w-67 {
  width: 67% !important;
}

.w-68 {
  width: 68% !important;
}

.w-69 {
  width: 69% !important;
}

.w-70 {
  width: 70% !important;
}

.w-71 {
  width: 71% !important;
}

.w-72 {
  width: 72% !important;
}

.w-73 {
  width: 73% !important;
}

.w-74 {
  width: 74% !important;
}

.w-75 {
  width: 75% !important;
}

.w-76 {
  width: 76% !important;
}

.w-77 {
  width: 77% !important;
}

.w-78 {
  width: 78% !important;
}

.w-79 {
  width: 79% !important;
}

.w-80 {
  width: 80% !important;
}

.w-81 {
  width: 81% !important;
}

.w-82 {
  width: 82% !important;
}

.w-83 {
  width: 83% !important;
}

.w-84 {
  width: 84% !important;
}

.w-85 {
  width: 85% !important;
}

.w-86 {
  width: 86% !important;
}

.w-87 {
  width: 87% !important;
}

.w-88 {
  width: 88% !important;
}

.w-89 {
  width: 89% !important;
}

.w-90 {
  width: 90% !important;
}

.w-91 {
  width: 91% !important;
}

.w-92 {
  width: 92% !important;
}

.w-93 {
  width: 93% !important;
}

.w-94 {
  width: 94% !important;
}

.w-95 {
  width: 95% !important;
}

.w-96 {
  width: 96% !important;
}

.w-97 {
  width: 97% !important;
}

.w-98 {
  width: 98% !important;
}

.w-99 {
  width: 99% !important;
}

.w-100 {
  width: 100% !important;
}

.h-0 {
  height: 0% !important;
}

.h-1 {
  height: 1% !important;
}

.h-2 {
  height: 2% !important;
}

.h-3 {
  height: 3% !important;
}

.h-4 {
  height: 4% !important;
}

.h-5 {
  height: 5% !important;
}

.h-6 {
  height: 6% !important;
}

.h-7 {
  height: 7% !important;
}

.h-8 {
  height: 8% !important;
}

.h-9 {
  height: 9% !important;
}

.h-10 {
  height: 10% !important;
}

.h-11 {
  height: 11% !important;
}

.h-12 {
  height: 12% !important;
}

.h-13 {
  height: 13% !important;
}

.h-14 {
  height: 14% !important;
}

.h-15 {
  height: 15% !important;
}

.h-16 {
  height: 16% !important;
}

.h-17 {
  height: 17% !important;
}

.h-18 {
  height: 18% !important;
}

.h-19 {
  height: 19% !important;
}

.h-20 {
  height: 20% !important;
}

.h-21 {
  height: 21% !important;
}

.h-22 {
  height: 22% !important;
}

.h-23 {
  height: 23% !important;
}

.h-24 {
  height: 24% !important;
}

.h-25 {
  height: 25% !important;
}

.h-26 {
  height: 26% !important;
}

.h-27 {
  height: 27% !important;
}

.h-28 {
  height: 28% !important;
}

.h-29 {
  height: 29% !important;
}

.h-30 {
  height: 30% !important;
}

.h-31 {
  height: 31% !important;
}

.h-32 {
  height: 32% !important;
}

.h-33 {
  height: 33% !important;
}

.h-34 {
  height: 34% !important;
}

.h-35 {
  height: 35% !important;
}

.h-36 {
  height: 36% !important;
}

.h-37 {
  height: 37% !important;
}

.h-38 {
  height: 38% !important;
}

.h-39 {
  height: 39% !important;
}

.h-40 {
  height: 40% !important;
}

.h-41 {
  height: 41% !important;
}

.h-42 {
  height: 42% !important;
}

.h-43 {
  height: 43% !important;
}

.h-44 {
  height: 44% !important;
}

.h-45 {
  height: 45% !important;
}

.h-46 {
  height: 46% !important;
}

.h-47 {
  height: 47% !important;
}

.h-48 {
  height: 48% !important;
}

.h-49 {
  height: 49% !important;
}

.h-50 {
  height: 50% !important;
}

.h-51 {
  height: 51% !important;
}

.h-52 {
  height: 52% !important;
}

.h-53 {
  height: 53% !important;
}

.h-54 {
  height: 54% !important;
}

.h-55 {
  height: 55% !important;
}

.h-56 {
  height: 56% !important;
}

.h-57 {
  height: 57% !important;
}

.h-58 {
  height: 58% !important;
}

.h-59 {
  height: 59% !important;
}

.h-60 {
  height: 60% !important;
}

.h-61 {
  height: 61% !important;
}

.h-62 {
  height: 62% !important;
}

.h-63 {
  height: 63% !important;
}

.h-64 {
  height: 64% !important;
}

.h-65 {
  height: 65% !important;
}

.h-66 {
  height: 66% !important;
}

.h-67 {
  height: 67% !important;
}

.h-68 {
  height: 68% !important;
}

.h-69 {
  height: 69% !important;
}

.h-70 {
  height: 70% !important;
}

.h-71 {
  height: 71% !important;
}

.h-72 {
  height: 72% !important;
}

.h-73 {
  height: 73% !important;
}

.h-74 {
  height: 74% !important;
}

.h-75 {
  height: 75% !important;
}

.h-76 {
  height: 76% !important;
}

.h-77 {
  height: 77% !important;
}

.h-78 {
  height: 78% !important;
}

.h-79 {
  height: 79% !important;
}

.h-80 {
  height: 80% !important;
}

.h-81 {
  height: 81% !important;
}

.h-82 {
  height: 82% !important;
}

.h-83 {
  height: 83% !important;
}

.h-84 {
  height: 84% !important;
}

.h-85 {
  height: 85% !important;
}

.h-86 {
  height: 86% !important;
}

.h-87 {
  height: 87% !important;
}

.h-88 {
  height: 88% !important;
}

.h-89 {
  height: 89% !important;
}

.h-90 {
  height: 90% !important;
}

.h-91 {
  height: 91% !important;
}

.h-92 {
  height: 92% !important;
}

.h-93 {
  height: 93% !important;
}

.h-94 {
  height: 94% !important;
}

.h-95 {
  height: 95% !important;
}

.h-96 {
  height: 96% !important;
}

.h-97 {
  height: 97% !important;
}

.h-98 {
  height: 98% !important;
}

.h-99 {
  height: 99% !important;
}

.h-100 {
  height: 100% !important;
}


.default-theme:hover {
  background: linear-gradient(30deg, #262427 50%, #D4AF37 75%, #3A75C4 100%) !important;
  color: #ffffff !important;
}

.energy-theme:hover {
  background: linear-gradient(30deg, #D9D9D9 50%, #FF5733 75%, #4CAF50 100%) !important;
  color: #000000 !important;
}

.vibe-theme:hover {
  background: linear-gradient(30deg, #2E4053 50%, #1ABC9C 75%, #FF5733 100%) !important;
  color: #ffffff !important;
}

.classic-theme:hover {
  background: linear-gradient(30deg, #2C3E50 50%, #7F8C8D 75%, #34495E 100%) !important;
  color: #ffffff !important;
}

.attractive-theme:hover {
  background: linear-gradient(30deg, #262427 50%, #7823c7 75%, #00FFD1 100%) !important;
  color: #ffffff !important;
}

.has-popover {
  position: relative;
}

.fs-1 {
  font-size: 1pt !important;
}

.fs-2 {
  font-size: 2pt !important;
}

.fs-3 {
  font-size: 3pt !important;
}

.fs-4 {
  font-size: 4pt !important;
}

.fs-5 {
  font-size: 5pt !important;
}

.fs-6 {
  font-size: 6pt !important;
}

.fs-7 {
  font-size: 7pt !important;
}

.fs-8 {
  font-size: 8pt !important;
}

.fs-9 {
  font-size: 9pt !important;
}

.fs-10 {
  font-size: 10pt !important;
}

.fs-11 {
  font-size: 11pt !important;
}

.fs-12 {
  font-size: 12pt !important;
}

.fs-13 {
  font-size: 13pt !important;
}

.fs-14 {
  font-size: 14pt !important;
}

.fs-15 {
  font-size: 15pt !important;
}

.fs-16 {
  font-size: 16pt !important;
}

.fs-17 {
  font-size: 17pt !important;
}

.fs-18 {
  font-size: 18pt !important;
}

.fs-19 {
  font-size: 19pt !important;
}

.fs-20 {
  font-size: 20pt !important;
}