.athx-popover-content {
  display: block;
  background-color: var(--primary);
  color: var(--primary-text);
  box-shadow: 0px 0px 2rem 1rem rgba(var(--accent-rgb), .5);
  padding: .5rem; 
  border-radius: .5rem;
  height: auto !important;
  min-width: 300px;
  z-index: 999999999999;

  &>div{
    height:auto;
  }
}
.athx-popover-trigger {
  position: relative;
}
