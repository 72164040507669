.alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: none;

  &:has(.alert-window) {
    display: flex;
  }

  justify-content: center;
  align-items: center;


  .alert-container {
    position: relative;

    .alert-window {
      --theme: var(--accent-rgb);

      &.primary {
        --theme: var(--primary-rgb);
      }

      &.accent {
        --theme: var(--accent-rgb);
      }

      // do also for secondary, interactivem, error, warning, success, info
      &.secondary {
        --theme: var(--secondary-rgb);
      }

      &.interactive {
        --theme: var(--interactive-rgb);
      }

      &.error {
        --theme: var(--error-rgb);
      }

      &.warning {
        --theme: var(--warning-rgb);
      }

      &.success {
        --theme: var(--success-rgb);
      }

      &.info {
        --theme: var(--info-rgb);
      }


      background-color: var(--primary) !important;
      color: var(--primary-text) !important;
      box-shadow: 0px 0px 10px 4px rgba(var(--theme), .5);
      border-radius: .5rem;
      width: 500px;
      max-width: 500px;
      height: 100%;
      max-height: 20rem;
      display: flex;
      flex-direction: column;


      .athx-alert-window-header {
        height: 8rem;
        width: 100%;
        padding: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;


        .athx-alert-window-close {
          position: absolute;
          right: .5rem;
          top: .5rem;
          padding: .5rem;
          background-color: transparent;
          color: var(--primary-text);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          outline: none;

          &:hover,
          &:focus {
            background-color: rgba(var(--error-rgb), .3);
            border: none;
            outline: none;
          }

          cursor: pointer;
        }

      }

      .athx-alert-window-body {
        height: calc(20rem - 8rem);
        max-height: calc(20rem - 8rem);
        overflow-y: auto;
        display: flex;
        padding: 1rem;
        flex-direction: column;

        h3 {
          width: 100%;
          text-align: center;
          margin: 0px;
        }

        p {
          width: 100%;
          text-align: center;
          font-size: 1.25rem;
        }
      }

      &:has(.athx-alert-window-footer) {
        .athx-alert-window-body {
          height: calc(20rem - 8rem - 2rem);
          max-height: calc(20rem - 8rem - 2rem);
        }
      }

      .athx-alert-window-footer {
        height: 2rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: .5rem;
        padding-right: .5rem;

        .progress-bar-container {
          width: 100%;
          height: .25rem;
          border-radius: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--primary-light);

          .progress-bar {
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            background-color: var(--accent);
            animation: toZero 3s linear forwards;
            -webkit-animation: toZero 3s linear forwards;
          }
        }
      }
    }
  }
}

@keyframes toZero {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@-webkit-keyframes toZero {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@media screen and (max-width: 500px) {
  .alert-window {
    width: calc(100dvw - .75rem) !important;
    max-width: calc(100dvw - .75rem) !important;
  }
}