.athx-bg-primary {
  background-color: var(--primary);
  color: var(--primary-text);
}

.athx-bg-primary-light {
  background-color: var(--primary-light);
  color: var(--primary-light-text);
}

.athx-bg-primary-dark {
  background-color: var(--primary-dark);
  color: var(--primary-dark-text);
}


.athx-bg-secondary {
  background-color: var(--secondary);
  color: var(--secondary-text);
}

.athx-bg-secondary-light {
  background-color: var(--secondary-light);
  color: var(--secondary-light-text);
}

.athx-bg-secondary-dark {
  background-color: var(--secondary-dark);
  color: var(--secondary-dark-text);
}


.athx-bg-accent {
  background-color: var(--accent);
  color: var(--accent-text);
}

.athx-bg-accent-light {
  background-color: var(--accent-light);
  color: var(--accent-light-text);
}

.athx-bg-accent-dark {
  background-color: var(--accent-dark);
  color: var(--accent-dark-text);
}


.athx-bg-error {
  background-color: var(--error);
  color: var(--error-text);
}

.athx-bg-error-light {
  background-color: var(--error-light);
  color: var(--error-light-text);
}

.athx-bg-error-dark {
  background-color: var(--error-dark);
  color: var(--error-dark-text);
}


.athx-bg-warning {
  background-color: var(--warning);
  color: var(--warning-text);
}

.athx-bg-warning-light {
  background-color: var(--warning-light);
  color: var(--warning-light-text);
}

.athx-bg-warning-dark {
  background-color: var(--warning-dark);
  color: var(--warning-dark-text);
}


.athx-bg-success {
  background-color: var(--success);
  color: var(--success-text);
}

.athx-bg-success-light {
  background-color: var(--success-light);
  color: var(--success-light-text);
}

.athx-bg-success-dark {
  background-color: var(--success-dark);
  color: var(--success-dark-text);
}


.athx-bg-info {
  background-color: var(--info);
  color: var(--info-text);
}

.athx-bg-info-light {
  background-color: var(--info-light);
  color: var(--info-light-text);
}

.athx-bg-info-dark {
  background-color: var(--info-dark);
  color: var(--info-dark-text);
}


.athx-bg-interactive {
  background-color: var(--interactive);
  color: var(--interactive-text);
}

.athx-bg-interactive-light {
  background-color: var(--interactive-light);
  color: var(--interactive-light-text);
}

.athx-bg-interactive-dark {
  background-color: var(--interactive-dark);
  color: var(--interactive-dark-text);
}