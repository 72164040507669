button.f-menu-trigger {
  background: linear-gradient(145deg, var(--accent-dark), var(--accent-light), var(--accent), var(--accent-light), var(--accent-dark));
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;
  font-size: 16px;
  height: 3rem;
  width: 3rem;
  font-weight: bold;
  border-radius: .5rem;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 .25rem rgba(var(--primary-rgb), 0.6), 0 0 .5rem rgba(var(--primary-rgb-dark), 0.3);
  text-align: center;


  &:not(.f-app-menu).has-events::after {
    position: absolute;
    top: -.125rem;
    right: -.125rem;
    width: .75rem;
    height: .75rem;
    background: var(--interactive-light);
    border-radius: 50%;
    content: '';
    animation: eventspulse 3s infinite;
    -webkit-animation: eventspulse 3s infinite;
  }



  &.f-app-menu::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: auto 70%;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    pointer-events: none;
    border-radius: inherit;
    background-image: url('../../../../../public/footer/menus/app-white.png');
  }

  &.f-app-menu.active::after {
    background-image: url('../../../../../public/footer/menus/app-active.png');
  }

  &>svg {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }

  &.active>svg {
    color: var(--interactive)
  }

  &.f-mini-menu.active>svg {
    transform: rotate(180deg);
  }

  &:hover,
  &:focus {
    background: linear-gradient(145deg, var(--accent), var(--accent-light), var(--accent-dark), var(--accent-light), var(--accent));
    box-shadow: 0 0 .25rem rgba(255, 255, 255, 0.8), 0 0 .5rem rgba(212, 175, 55, 1);
    outline: none;
    border: none;
  }

  &:active {
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
  }
}

@media screen and (max-width: 576px) {
  button.f-menu-trigger {
    height: 2rem;
    width: 2rem;
  }

}

@keyframes eventspulse {
  0% {
    opacity: 1;
  }

  35% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes eventspulse {
  0% {
    opacity: 1;
  }

  35% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}