.athx-btn {
  padding: .25rem .75rem;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease-in-out;
  outline: none;
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  --athx-btn-cursor: pointer;
  cursor: var(--athx-btn-cursor);


  &.sm{
    padding: .175rem .5rem;
    font-size: 1rem;
  }

  &.lg {
    padding: .5rem 1.25rem;
    font-size: 2rem;
  }

  /**
    NORMAL
  **/
  --athx-btn-background-color: var(--primary);
  --athx-btn-color: var(--primary-text);
  --athx-btn-hover-background-color: var(--primary-light);
  --athx-btn-hover-color: var(--primary-light-text);
  --athx-btn-focus-background-color: var(--primary-dark);
  --athx-btn-focus-box-shadow: 0px 0px 5px rgba(var(--primary-rgb), 0.7);
  --athx-btn-active-background-color: var(--primary-dark);
  --athx-btn-active-color: var(--primary-dark-text);
  --athx-btn-disabled-background-color: var(--primary-dark);
  --athx-btn-disabled-color: var(--primary-dark-text);
  /**
    OUTLINE
  **/
  --athx-btn-outline-border-color: var(--primary);
  --athx-btn-outline-color: var(--primary);
  --athx-btn-outline-hover-border-color: var(--primary-light);
  --athx-btn-outline-hover-color: var(--primary-light);
  --athx-btn-outline-focus-border-color: var(--primary-dark);
  --athx-btn-outline-focus-color: var(--primary-dark);
  --athx-btn-outline-active-border-color: var(--primary-dark);
  --athx-btn-outline-active-color: var(--primary-dark);
  --athx-btn-outline-disabled-border-color: var(--primary-dark);
  --athx-btn-outline-disabled-color: var(--primary-dark);
  /**
    LINK
  **/
  --athx-btn-link-color: var(--primary);
  --athx-btn-link-hover-color: var(--primary-light);
  --athx-btn-link-focus-color: var(--primary-dark);
  --athx-btn-link-active-color: var(--primary-dark);
  --athx-btn-link-disabled-color: var(--primary-dark);




  // APPLY

  &:not(.outline):not(.link) {
    background-color: var(--athx-btn-background-color);
    color: var(--athx-btn-color);
  }

  &.outline:not(.link) {
    background-color: transparent;
    border: .125rem solid var(--athx-btn-outline-border-color);
    color: var(--athx-btn-outline-color);
  }

  &.link:not(.outline) {
    background-color: transparent;
    color: var(--athx-btn-link-color);
  }







  &:hover:not(:disabled) {
    transform: translateY(-.125rem);
    background-color: var(--athx-btn-hover-background-color);
    color: var(--athx-btn-hover-color);
  }

  &:disabled {
    background-color: var(--athx-btn-disabled-background-color);
    color: var(--athx-btn-disabled-color);
    cursor: var(--athx-btn-cursor);
  }

  &:disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }


  &:active:not(:disabled) {
    transform: scale(0.98);
    background-color: var(--athx-btn-active-background-color);
    color: var(--athx-btn-active-color);
  }

  &:focus:not(:disabled) {
    background-color: var(--athx-btn-focus-background-color);
    box-shadow: var(--athx-btn-focus-box-shadow);
  }

  &.accent {
    /**
      NORMAL
    **/
    --athx-btn-background-color: var(--accent);
    --athx-btn-color: var(--accent-text);
    --athx-btn-hover-background-color: var(--accent-light);
    --athx-btn-hover-color: var(--accent-light-text);
    --athx-btn-focus-background-color: var(--accent-dark);
    --athx-btn-active-background-color: var(--accent-dark);
    --athx-btn-active-color: var(--accent-dark-text);
    --athx-btn-disabled-background-color: var(--accent-dark);
    --athx-btn-disabled-color: var(--accent-dark-text);
    /**
      OUTLINE
    **/
    --athx-btn-outline-border-color: var(--accent);
    --athx-btn-outline-color: var(--accent);
    --athx-btn-outline-hover-border-color: var(--accent-light);
    --athx-btn-outline-hover-color: var(--accent-light);
    --athx-btn-outline-focus-border-color: var(--accent-dark);
    --athx-btn-outline-focus-color: var(--accent-dark);
    --athx-btn-outline-active-border-color: var(--accent-dark);
    --athx-btn-outline-active-color: var(--accent-dark);
    --athx-btn-outline-disabled-border-color: var(--accent-dark);
    --athx-btn-outline-disabled-color: var(--accent-dark);
    /**
      LINK
    **/
    --athx-btn-link-color: var(--accent);
    --athx-btn-link-hover-color: var(--accent-light);
    --athx-btn-link-focus-color: var(--accent-dark);
    --athx-btn-link-active-color: var(--accent-dark);
    --athx-btn-link-disabled-color: var(--accent-dark);
  }

  &.secondary {
    /**
      NORMAL
    **/
    --athx-btn-background-color: var(--secondary);
    --athx-btn-color: var(--secondary-text);
    --athx-btn-hover-background-color: var(--secondary-light);
    --athx-btn-hover-color: var(--secondary-light-text);
    --athx-btn-focus-background-color: var(--secondary-dark);
    --athx-btn-active-background-color: var(--secondary-dark);
    --athx-btn-active-color: var(--secondary-dark-text);
    --athx-btn-disabled-background-color: var(--secondary-dark);
    --athx-btn-disabled-color: var(--secondary-dark-text);
    /**
      OUTLINE
    **/
    --athx-btn-outline-border-color: var(--secondary);
    --athx-btn-outline-color: var(--secondary);
    --athx-btn-outline-hover-border-color: var(--secondary-light);
    --athx-btn-outline-hover-color: var(--secondary-light);
    --athx-btn-outline-focus-border-color: var(--secondary-dark);
    --athx-btn-outline-focus-color: var(--secondary-dark);
    --athx-btn-outline-active-border-color: var(--secondary-dark);
    --athx-btn-outline-active-color: var(--secondary-dark);
    --athx-btn-outline-disabled-border-color: var(--secondary-dark);
    --athx-btn-outline-disabled-color: var(--secondary-dark);
    /**
      LINK
    **/
    --athx-btn-link-color: var(--secondary);
    --athx-btn-link-hover-color: var(--secondary-light);
    --athx-btn-link-focus-color: var(--secondary-dark);
    --athx-btn-link-active-color: var(--secondary-dark);
    --athx-btn-link-disabled-color: var(--secondary-dark);
  }

  &.interactive {
    /**
      NORMAL
    **/
    --athx-btn-background-color: var(--interactive);
    --athx-btn-color: var(--interactive-text);
    --athx-btn-hover-background-color: var(--interactive-light);
    --athx-btn-hover-color: var(--interactive-light-text);
    --athx-btn-focus-background-color: var(--interactive-dark);
    --athx-btn-active-background-color: var(--interactive-dark);
    --athx-btn-active-color: var(--interactive-dark-text);
    --athx-btn-disabled-background-color: var(--interactive-dark);
    --athx-btn-disabled-color: var(--interactive-dark-text);
    /**
      OUTLINE
    **/
    --athx-btn-outline-border-color: var(--interactive);
    --athx-btn-outline-color: var(--interactive);
    --athx-btn-outline-hover-border-color: var(--interactive-light);
    --athx-btn-outline-hover-color: var(--interactive-light);
    --athx-btn-outline-focus-border-color: var(--interactive-dark);
    --athx-btn-outline-focus-color: var(--interactive-dark);
    --athx-btn-outline-active-border-color: var(--interactive-dark);
    --athx-btn-outline-active-color: var(--interactive-dark);
    --athx-btn-outline-disabled-border-color: var(--interactive-dark);
    --athx-btn-outline-disabled-color: var(--interactive-dark);
    /**
      LINK
    **/
    --athx-btn-link-color: var(--interactive);
    --athx-btn-link-hover-color: var(--interactive-light);
    --athx-btn-link-focus-color: var(--interactive-dark);
    --athx-btn-link-active-color: var(--interactive-dark);
    --athx-btn-link-disabled-color: var(--interactive-dark);
  }

  &.error {
    /**
      NORMAL
    **/
    --athx-btn-background-color: var(--error);
    --athx-btn-color: var(--error-text);
    --athx-btn-hover-background-color: var(--error-light);
    --athx-btn-hover-color: var(--error-light-text);
    --athx-btn-focus-background-color: var(--error-dark);
    --athx-btn-active-background-color: var(--error-dark);
    --athx-btn-active-color: var(--error-dark-text);
    --athx-btn-disabled-background-color: var(--error-dark);
    --athx-btn-disabled-color: var(--error-dark-text);
    /**
      OUTLINE
    **/
    --athx-btn-outline-border-color: var(--error);
    --athx-btn-outline-color: var(--error);
    --athx-btn-outline-hover-border-color: var(--error-light);
    --athx-btn-outline-hover-color: var(--error-light);
    --athx-btn-outline-focus-border-color: var(--error-dark);
    --athx-btn-outline-focus-color: var(--error-dark);
    --athx-btn-outline-active-border-color: var(--error-dark);
    --athx-btn-outline-active-color: var(--error-dark);
    --athx-btn-outline-disabled-border-color: var(--error-dark);
    --athx-btn-outline-disabled-color: var(--error-dark);
    /**
      LINK
    **/
    --athx-btn-link-color: var(--error);
    --athx-btn-link-hover-color: var(--error-light);
    --athx-btn-link-focus-color: var(--error-dark);
    --athx-btn-link-active-color: var(--error-dark);
    --athx-btn-link-disabled-color: var(--error-dark);
  }

  &.warning {
    /**
      NORMAL
    **/
    --athx-btn-background-color: var(--warning);
    --athx-btn-color: var(--warning-text);
    --athx-btn-hover-background-color: var(--warning-light);
    --athx-btn-hover-color: var(--warning-light-text);
    --athx-btn-focus-background-color: var(--warning-dark);
    --athx-btn-active-background-color: var(--warning-dark);
    --athx-btn-active-color: var(--warning-dark-text);
    --athx-btn-disabled-background-color: var(--warning-dark);
    --athx-btn-disabled-color: var(--warning-dark-text);
    /**
      OUTLINE
    **/
    --athx-btn-outline-border-color: var(--warning);
    --athx-btn-outline-color: var(--warning);
    --athx-btn-outline-hover-border-color: var(--warning-light);
    --athx-btn-outline-hover-color: var(--warning-light);
    --athx-btn-outline-focus-border-color: var(--warning-dark);
    --athx-btn-outline-focus-color: var(--warning-dark);
    --athx-btn-outline-active-border-color: var(--warning-dark);
    --athx-btn-outline-active-color: var(--warning-dark);
    --athx-btn-outline-disabled-border-color: var(--warning-dark);
    --athx-btn-outline-disabled-color: var(--warning-dark);
    /**
      LINK
    **/
    --athx-btn-link-color: var(--warning);
    --athx-btn-link-hover-color: var(--warning-light);
    --athx-btn-link-focus-color: var(--warning-dark);
    --athx-btn-link-active-color: var(--warning-dark);
    --athx-btn-link-disabled-color: var(--warning-dark);
  }

  &.success {
    /**
      NORMAL
    **/
    --athx-btn-background-color: var(--success);
    --athx-btn-color: var(--success-text);
    --athx-btn-hover-background-color: var(--success-light);
    --athx-btn-hover-color: var(--success-light-text);
    --athx-btn-focus-background-color: var(--success-dark);
    --athx-btn-active-background-color: var(--success-dark);
    --athx-btn-active-color: var(--success-dark-text);
    --athx-btn-disabled-background-color: var(--success-dark);
    --athx-btn-disabled-color: var(--success-dark-text);
    /**
      OUTLINE
    **/
    --athx-btn-outline-border-color: var(--success);
    --athx-btn-outline-color: var(--success);
    --athx-btn-outline-hover-border-color: var(--success-light);
    --athx-btn-outline-hover-color: var(--success-light);
    --athx-btn-outline-focus-border-color: var(--success-dark);
    --athx-btn-outline-focus-color: var(--success-dark);
    --athx-btn-outline-active-border-color: var(--success-dark);
    --athx-btn-outline-active-color: var(--success-dark);
    --athx-btn-outline-disabled-border-color: var(--success-dark);
    --athx-btn-outline-disabled-color: var(--success-dark);
    /**
      LINK
    **/
    --athx-btn-link-color: var(--success);
    --athx-btn-link-hover-color: var(--success-light);
    --athx-btn-link-focus-color: var(--success-dark);
    --athx-btn-link-active-color: var(--success-dark);
    --athx-btn-link-disabled-color: var(--success-dark);
  }

  &.info {
    /**
      NORMAL
    **/
    --athx-btn-background-color: var(--info);
    --athx-btn-color: var(--info-text);
    --athx-btn-hover-background-color: var(--info-light);
    --athx-btn-hover-color: var(--info-light-text);
    --athx-btn-focus-background-color: var(--info-dark);
    --athx-btn-active-background-color: var(--info-dark);
    --athx-btn-active-color: var(--info-dark-text);
    --athx-btn-disabled-background-color: var(--info-dark);
    --athx-btn-disabled-color: var(--info-dark-text);
    /**
      OUTLINE
    **/
    --athx-btn-outline-border-color: var(--info);
    --athx-btn-outline-color: var(--info);
    --athx-btn-outline-hover-border-color: var(--info-light);
    --athx-btn-outline-hover-color: var(--info-light);
    --athx-btn-outline-focus-border-color: var(--info-dark);
    --athx-btn-outline-focus-color: var(--info-dark);
    --athx-btn-outline-active-border-color: var(--info-dark);
    --athx-btn-outline-active-color: var(--info-dark);
    --athx-btn-outline-disabled-border-color: var(--info-dark);
    --athx-btn-outline-disabled-color: var(--info-dark);
    /**
      LINK
    **/
    --athx-btn-link-color: var(--info);
    --athx-btn-link-hover-color: var(--info-light);
    --athx-btn-link-focus-color: var(--info-dark);
    --athx-btn-link-active-color: var(--info-dark);
    --athx-btn-link-disabled-color: var(--info-dark);
  }


}