@import './src/index.scss';

#footer {
  width: 100%;
  height: 3.5rem;
  background-color: var(--primary-dark);
  color: var(--background-light);
  position: fixed;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px 0px .25rem 0px var(--accent);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 0px;

  .f-item {
    display: flex;
    height: 3.5rem;
    align-items: center;
    padding: 0px;


    &.left-menu {
      max-width: 124px;
      width: 124px;
      min-width: 124px;
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 0px;
      margin: 0px;
      padding: 0px .5rem;
      outline: none;
    }

    &.center-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      margin: 0px;
      position: relative;

      &:not(:has(button.active)) {
        display: none;
      }
    }

    &.right-menu {
      max-width: 300px;
      width: auto;
      min-width: 100px;
      display: flex;
      justify-content: end;
      align-items: center;
      padding: 0px;
      margin: 0px;
    }
  }
}


.active-athx-window-list {
  display: none;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 100%;
  overflow-y: auto;
  height: 150px;
  position: fixed;
  bottom: calc(3.5rem - 0px);
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px -5px 10px 5px rgba(0, 0, 0, .5);


  .athx-win-mini {

    min-width: calc(150px - 1rem - 1rem);
    width: calc(150px - 1rem - 1rem);
    height: calc(150px - 1rem - 1rem);
    background-color: var(--primary-dark);
    color: var(--background-light);
    display: flex;
    flex-direction: column;

    .athx-win-mini-header {

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        padding-left: 4px;
        padding-right: 4px;
        width: 96px;
        max-width: 96px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // only one row
        height: 20px;
      }

      .athx-win-mini-closer {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: var(--error);
        color: var(--error-text);
        border-radius: 0px;
        padding: 0px;
        border: 0px;
        font-size: 1rem !important;
      }
    }


    &:hover {
      box-shadow: 0px 0px 5px 0px var(--accent);
    }
  }
}

.active-list-toggler {
  position: relative;
}

.active-list-toggler-menu {
  position: absolute;
  height: 120px;
  width: 300px;
  background-color: var(--primary);
  color: var(--background);
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px;
  margin: 0px;
  top: -125px;
  // right: 75px;
  box-shadow: 0px 0px 5px 0px var(--accent);

  z-index: 1000;

  .toggler-menu-item {
    cursor: pointer;
    border: 0px;
    background-color: var(--secondary-dark);
    color: var(--secondary-dark-text);
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    outline: none;
    font-family: "Poppins", sans-serif;

    &:hover {
      background-color: var(--secondary);
      color: var(--secondary-text);
    }

  }
}

#root:not(:has(.active-list-toggler.context)):has(.active-list-toggler:hover),
#root:not(:has(.active-list-toggler.context)):has(.center-menu:hover),
#root:not(:has(.active-list-toggler.context)):has(.active-athx-window-list:hover) {
  .active-athx-window-list {
    display: flex;
  }
}

#root:not(:has(.active-list-toggler.context)) {
  .active-list-toggler-menu {
    display: none !important;
  }
}

@mixin footerSmall {
  #footer {
    height: 3rem;

    .f-item {
      height: 3rem;
    }
  }
}

html[athx="mobile"] {
  @include footerSmall;
}

@media screen and (max-width: 576px) {
  @include footerSmall;
}