/**
  * Common text classes
  */
.t-t-u {
  text-transform: uppercase !important;
}

.t-t-l {
  text-transform: lowercase !important;
}

.t-t-c {
  text-transform: capitalize !important;
}

.t-t-n {
  text-transform: none !important;
}

.t-a-l {
  text-align: left !important;
}

.t-a-r {
  text-align: right !important;
}

.t-a-c {
  text-align: center !important;
}

.t-a-j {
  text-align: justify !important;
}

.t-d-i {
  text-decoration: inherit !important;
}

.t-d-n {
  text-decoration: none !important;
}

.t-d-u {
  text-decoration: underline !important;
}

.t-d-u-d {
  text-decoration: underline !important;
}

.t-d-o {
  text-decoration: overline !important;
}

.t-d-l-t {
  text-decoration: line-through !important;
}

.t-e {
  max-width: calc(100% - 1px) !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

/**
 * Text color classes
 */
.text-primary {
  color: var(--primary) !important;
}

.text-primary-light {
  color: var(--primary-light) !important;
}

.text-primary-dark {
  color: var(--primary-dark) !important;
}

.text-primary-text {
  color: var(--primary-text) !important;
}

.text-primary-light-text {
  color: var(--primary-light-text) !important;
}

.text-primary-dark-text {
  color: var(--primary-dark-text) !important;
}

.text-accent {
  color: var(--accent) !important;
}

.text-accent-light {
  color: var(--accent-light) !important;
}

.text-accent-dark {
  color: var(--accent-dark) !important;
}

.text-accent-text {
  color: var(--accent-text) !important;
}

.text-accent-light-text {
  color: var(--accent-light-text) !important;
}

.text-accent-dark-text {
  color: var(--accent-dark-text) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-secondary-light {
  color: var(--secondary-light) !important;
}

.text-secondary-dark {
  color: var(--secondary-dark) !important;
}

.text-secondary-text {
  color: var(--secondary-text) !important;
}

.text-secondary-light-text {
  color: var(--secondary-light-text) !important;
}

.text-secondary-dark-text {
  color: var(--secondary-dark-text) !important;
}

.text-interactive {
  color: var(--interactive) !important;
}

.text-interactive-light {
  color: var(--interactive-light) !important;
}

.text-interactive-dark {
  color: var(--interactive-dark) !important;
}

.text-interactive-text {
  color: var(--interactive-text) !important;
}

.text-interactive-light-text {
  color: var(--interactive-light-text) !important;
}

.text-interactive-dark-text {
  color: var(--interactive-dark-text) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-success-light {
  color: var(--success-light) !important;
}

.text-success-dark {
  color: var(--success-dark) !important;
}

.text-success-text {
  color: var(--success-text) !important;
}

.text-success-light-text {
  color: var(--success-light-text) !important;
}

.text-success-dark-text {
  color: var(--success-dark-text) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-warning-light {
  color: var(--warning-light) !important;
}

.text-warning-dark {
  color: var(--warning-dark) !important;
}

.text-warning-text {
  color: var(--warning-text) !important;
}

.text-warning-light-text {
  color: var(--warning-light-text) !important;
}

.text-warning-dark-text {
  color: var(--warning-dark-text) !important;
}

.text-error {
  color: var(--error) !important;
}

.text-error-light {
  color: var(--error-light) !important;
}

.text-error-dark {
  color: var(--error-dark) !important;
}

.text-error-text {
  color: var(--error-text) !important;
}

.text-error-light-text {
  color: var(--error-light-text) !important;
}

.text-error-dark-text {
  color: var(--error-dark-text) !important;
}

.text-info {
  color: var(--info) !important;
}

.text-info-light {
  color: var(--info-light) !important;
}

.text-info-dark {
  color: var(--info-dark) !important;
}

.text-info-text {
  color: var(--info-text) !important;
}

.text-info-light-text {
  color: var(--info-light-text) !important;
}

.text-info-dark-text {
  color: var(--info-dark-text) !important;
}