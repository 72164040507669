ul.athm-messages-body {
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;

  li {
    width: 100%;
    height: 3.5rem;
    padding: 0px;
    margin-bottom: .25rem;
    display: flex;



    &:not(:first-child) {
      margin-top: .25rem;
    }

    &:not(:last-child) {
      margin-bottom: .25rem;
    }


    &>a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .25rem;
      font-size: 12pt;
      text-decoration: none;
      text-transform: none;
      background-color: var(--primary);
      color: var(--primary-text);
      flex: 1 1 auto;
      margin: 0px;
      border-radius: calc((var(--athenorix-menu-border-radius) - var(--athenorix-menu-padding)) - .25rem);

      &:hover {
        box-shadow: inset 0px 0px 2px 1px var(--accent);
      }

      &:active {
        box-shadow: 0px 0px 2px 1px var(--accent-light);
        background-color: var(--primary-dark);
        color: var(--primary-dark-text);
      }


      .msg-img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin-right: .5rem;
        box-shadow: 0px 0px 2px 1px var(--accent);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          object-fit: cover;
        }

      }

      .msg-body {
        width: calc(100% - 6rem);
        height: 100%;
        max-width: calc(100% - 6rem);

        .msg-from {
          overflow: hidden;
          text-wrap: nowrap;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .msg-msg {
          overflow: hidden;
          text-wrap: nowrap;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10pt;
          font-weight: 500;

          &>span {
            &>span {
              font-weight: 300;
              color: var(--interactive-light)
            }
          }

          &.from-receiver {
            font-weight: 200;
          }

          &:not(.from-receiver).read {
            font-weight: 300;
          }

        }


      }

      .msg-desk {
        width: 3rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px;
        margin: 0px;

        &>span {
          width: 100%;
          text-align: center;
          font-size: 9pt;
          color: var(--interactive);
          display: flex;
          justify-content: center;
          align-items: center;
          text-shadow: 0px 0px 1px var(--interactive-light);

          &:has(svg).read{
            color: var(--accent);
          }
        }


        span.msg-count {
          margin-bottom: .25rem;
          border-radius: 50%;
          height: 1.5rem;
          width: 1.5rem;
          background-color: var(--interactive);
          color: var(--interactive-text);
          display: flex;
          justify-content: center;
          align-items: center;

          
        }

      }
    }
  }
}