.athx-table-container {
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  width: 100% !important;
  display: flex;
  flex-direction: column;

  .athx-table-container-header {
    width: 100%;
    margin-bottom: 1rem;
    background-color: var(--primary-light);
    color: var(--primary-light-text);
  }

  .athx-table-container-body {
    padding: 0px;
    margin: 0px;


    .athx-table {
      min-width: 100%;
      max-width: 100%;
      background-color: var(--primary-light);
      border-collapse: collapse;


      &.resizing {
        tbody {
          display: none;
          // visibility: hidden;
          // opacity: 0;
        }
      }


      tr {
        margin: 0px;
        padding: 0px;

        td,
        th {
          margin: 0px;
          padding: 0px;
        }
      }


      color: var(--primary-light-text);
      border: 0px;
      padding: 0px;
      margin: 0px;
      font-size: 1rem;
      padding: 0px;
      margin: 0px;
      border-collapse: collapse;
      position: relative;




      thead {
        position: sticky;
        top: -.6rem;
        z-index: 2;
        left: 0px;
        background-color: var(--primary-light) !important;
        color: var(--primary-light-text) !important;
      }

      tbody {
        position: relative;
        z-index: 0;


        .athx-td {

          &.no-wrap {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }


      &.athx-hide-1 {

        .athx-column-1,
        .athx-column-2,
        .athx-column-3,
        .athx-column-4,
        .athx-column-5,
        .athx-column-6,
        .athx-column-7,
        .athx-column-8,
        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-2 {

        .athx-column-2,
        .athx-column-3,
        .athx-column-4,
        .athx-column-5,
        .athx-column-6,
        .athx-column-7,
        .athx-column-8,
        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-3 {

        .athx-column-3,
        .athx-column-4,
        .athx-column-5,
        .athx-column-6,
        .athx-column-7,
        .athx-column-8,
        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-4 {

        .athx-column-4,
        .athx-column-5,
        .athx-column-6,
        .athx-column-7,
        .athx-column-8,
        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-5 {

        .athx-column-5,
        .athx-column-6,
        .athx-column-7,
        .athx-column-8,
        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-6 {

        .athx-column-6,
        .athx-column-7,
        .athx-column-8,
        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-7 {

        .athx-column-7,
        .athx-column-8,
        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-8 {

        .athx-column-8,
        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-9 {

        .athx-column-9,
        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-10 {

        .athx-column-10,
        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-11 {

        .athx-column-11,
        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-12 {

        .athx-column-12,
        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-13 {

        .athx-column-13,
        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-14 {

        .athx-column-14,
        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-15 {

        .athx-column-15,
        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-16 {

        .athx-column-16,
        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-17 {

        .athx-column-17,
        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-18 {

        .athx-column-18,
        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-19 {

        .athx-column-19,
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-hide-20 {
        .athx-column-20 {
          display: none !important;
        }
      }

      &.athx-control {
        max-width: 2rem;
        width: 2rem;
        cursor: pointer;
        padding: 0px;
        margin: 0px;
        height: 2rem !important;
        width: 2rem !important;
        text-align: center;

        &>div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &:not([class*="athx-hide-"]) {
        .athx-control {
          display: none !important;
        }
      }



      thead tr {
        border-bottom: 1px solid var(--athx-window-body-background-color-accent);

        .athx-table-column-search-container {
          height: 2rem;
          padding: 0px;
          margin: 0px;

          .athx-table-column-search {
            width: 100%;
            height: 100%;
            padding: .25rem;
            margin: 0px;
            outline: none;
            border: 0px;

            &:focus,
            &:active {
              outline: none;
              border: 0px;
              box-shadow: 0px 0px 5px 1px var(--interactive);
            }

            &::-webkit-search-cancel-button {
              appearance: none;
              background-color: var(--primary);
              border: 0px;
              cursor: pointer;
              height: 16px;
              width: 16px;
              mask: url('../../../../public/form-elements/input/search-cross.svg');
              mask-size: cover;
              -webkit-mask: url('../../../../public/form-elements/input/search-cross.svg');
              -webkit-mask-size: cover;
            }

          }
        }

      }

      thead tr {
        border-bottom: 1px solid var(--athx-window-body-background-color-accent) !important;
      }

      tbody tr {

        &:has(.athx-table-row-context-menu) {
          position: relative;
        }

        .athx-table-row-context-menu {
          position: absolute;
          top: 0px;
          left: 0px;
          display: flex;
          flex-direction: row;
          justify-content: start;
          background-color: rgba(var(--interactive-rgb), .5);
          color: var(--interactive-text);
          width: 0%;
          height: 100%;
          opacity: 0;

          &.open {
            animation: athx-table-row-context-menu-open 0.5s forwards;
            -webkit-animation: athx-table-row-context-menu-open 0.5s forwards;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--athx-window-body-background-color-accent);
        }

        th,
        td {
          min-width: 0px;
          vertical-align: middle;
          padding: .25rem;
          margin: 0px;
          height: 2rem !important;

          &:not(.athx-control) {
            padding: 0.25rem;

            .center {
              text-align: center;
            }

            .start {
              text-align: start;
            }

            .end {
              text-align: end;
            }
          }
        }
      }

      tfoot {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
      }

      &.striped {
        tbody tr:nth-child(odd) {
          background-color: var(--primary);
          color: var(--primary-text);
        }
      }

      &.bordered {
        tr {
          border-bottom: 1px solid var(--athx-window-body-background-color-accent);
        }

        th,
        td {
          border: 1px solid var(--athx-window-body-background-color-accent);
        }
      }

      &.hoverable {
        tbody tr:hover {
          background-color: var(--primary-dark) !important;
          color: var(--primary-dark-text) !important;
        }
      }
    }
  }
}

@keyframes athx-table-row-context-menu-open {
  0% {
    width: 0%;
    opacity: 0;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes athx-table-row-context-menu-open {
  0% {
    width: 0%;
    opacity: 0;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}