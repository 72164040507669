#desktop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100dvh - 3.5rem);

  #content {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}