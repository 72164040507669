@import url('/src/styles/src/root/index.scss');
@import url('./src/variables.scss');


@import url('./src/common/common.scss');
@import url('./src/common/text.scss');
@import url('./src/common/display.scss');
@import url('./src/common/bg.scss');


// tests
@import url('./src/form/button/index.scss');
@import url('./src/form/input/index.scss');

@import url('./src/table/index.scss');

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  touch-action: manipulation;
}

// change font of all placeholders
input,
textarea,
::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  min-width: 100vw;
  width: 100vw;
  max-width: 100vw;
  min-height: 100dvh;
  height: 100dvh;
  max-height: 100dvh;
  letter-spacing: .05em;
  overflow: hidden;
}

#root {
  height: 100dvh;
  width: 100vw;
  background-image: url('../../public/wallpaper4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}


::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--accent);
  border-radius: .5rem;
}

::-webkit-scrollbar-track {
  background-color: var(--primary);
  border-radius: .5rem;
}

span.filigrana {
  position: fixed;
  bottom: 4rem;
  right: 1rem;
  color: white;
  text-shadow: 1px 1px 1px black;
  // opacity: .3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 1rem;

  img {
    max-width: 1.5rem;
    max-height: 1.5rem;
    margin-right: .5rem;
    // also add shadow to the image
    filter: drop-shadow(1px 1px 1px black);
  }
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}


