.d-inline {
  display: inline !important;
}

.d-block {
  display: block !important;
}

.d-contents {
  display: contents !important;
}

.d-flex {
  display: flex !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-inline-table {
  display: inline-table !important;
}

.d-list-item {
  display: list-item !important;
}

.d-run-in {
  display: run-in !important;
}

.d-table {
  display: table !important;
}

.d-table-caption {
  display: table-caption !important;
}

.d-table-column-group {
  display: table-column-group !important;
}

.d-table-header-group {
  display: table-header-group !important;
}

.d-table-footer-group {
  display: table-footer-group !important;
}

.d-table-row-group {
  display: table-row-group !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-table-column {
  display: table-column !important;
}

.d-table-row {
  display: table-row !important;
}

.d-none {
  display: none !important;
}

.d-initial {
  display: initial !important;
}

.d-inherit {
  display: inherit !important;
}

.j-c-c {
  justify-content: center !important;
}

.j-c-s {
  justify-content: start !important;
}

.j-c-e {
  justify-content: end !important;
}

.j-c-fs {
  justify-content: flex-start !important;
}

.j-c-fe {
  justify-content: flex-end !important;
}

.j-c-sb {
  justify-content: space-between !important;
}

.j-c-sa {
  justify-content: space-around !important;
}

.j-c-se {
  justify-content: space-evenly !important;
}

.j-c-l {
  justify-content: left !important;
}

.j-c-r {
  justify-content: right !important;
}

.j-c-n {
  justify-content: normal !important;
}

.j-c-i {
  justify-content: initial !important;
}

.j-c-i {
  justify-content: inherit !important;
}

.j-c-s {
  justify-content: stretch !important;
}

.a-i-c {
  align-items: center !important;
}

.a-i-s {
  align-items: start !important;
}

.a-i-e {
  align-items: end !important;
}

.a-i-fs {
  align-items: flex-start !important;
}

.a-i-fe {
  align-items: flex-end !important;
}

.a-i-b {
  align-items: baseline !important;
}

.a-i-s {
  align-items: stretch !important;
}

.a-i-ini {
  align-items: initial !important;
}

.a-i-inh {
  align-items: inherit !important;
}

.f-d-r {
  flex-direction: row !important;
}

.f-d-r-r {
  flex-direction: row-reverse !important;
}

.f-d-c {
  flex-direction: column !important;
}

.f-d-c-r {
  flex-direction: column-reverse !important;
}

