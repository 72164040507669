.athm-calendar-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  padding: .25rem;
  background-color: var(--primary);
  border-radius: .25rem;
  color: var(--primary-text);


  &:has(.calendar-month),
  &:has(.calendar-year) {
    .athx-cal-h>button {
      display: none;
    }
  }

}

.athx-cal-controllers {

  display: flex;
  width: 100%;
  padding: 0px;
  justify-content: space-between;
  align-items: center;
  height: 2rem;

  &>:where(.athx-cal-today, .athx-cal-a-e) {

    height: 2rem;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: .25rem;

    button {
      height: 2rem;
      border-radius: .125rem;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
      color: var(--primary-text);
      display: flex;
      align-items: center;
      justify-content: start;
      border-radius: .25rem;

      border: 2px solid var(--accent);

      &:hover {
        background-color: var(--accent);
        color: var(--accent-text);
      }
    }
  }
}

.athx-cal-h {
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &>.athx-current {
    cursor: pointer;
    border-bottom: 2px solid var(--accent);

  }


  .next-prev {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: .25rem;
  }

  button {
    width: 2rem;
    height: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: var(--primary-text);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;

    &:hover {
      background-color: var(--accent);
      color: var(--accent-text);
    }
  }
}

.athx-cal-b {
  display: grid;
  gap: 5px;

  .calendar-weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    font-weight: bold;
    text-align: center;
  }


  .calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }


  &:has(.calendar-month),
  &:has(.calendar-year) {
    overflow-x: auto;
  }


}

.calendar-day.today {
  border: 1px solid var(--accent);
}

.calendar-day {
  position: relative;

  &.selected {
    background-color: var(--accent-dark);
    color: var(--accent-dark-text);
  }
}

.calendar-day,
.calendar-month,
.calendar-year {
  padding: 10px;
  text-align: center;
  border-radius: .25rem;

  &:not(.other-month) {
    cursor: pointer;
  }

  color: var(--primary-text);
}

.calendar-day.empty {
  visibility: hidden;
}

.calendar-day.other-month {
  color: var(--secondary);
}

.calendar-month:not(.other-month):hover,
.calendar-year:not(.other-month):hover,
.calendar-day:not(.other-month):hover {
  background-color: var(--accent);
  color: var(--accent-text);
}

.calendar-month:not(.other-month):active,
.calendar-year:not(.other-month):active,
.calendar-day:not(.other-month):active {
  background-color: var(--primary);
  color: var(--primary-text);
}

.athx-crud-events {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .athx-c-crud-head {
    height: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .athx-c-e-title {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: .25rem;
    }
  }

  .athx-c-crud-body {
    width: 100%;
    height: calc(100% - 2rem - .5rem - 2rem);
    display: flex;
    flex-direction: column;
    margin-top: .5rem;
    background-color: var(--primary-light);
    color: var(--primary-light-text);
    padding: .5rem;
    border-radius: .25rem;


    .athx-c-crud-time {
      width: 100%;
      height: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;


      &>span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
      }

    }

    .athx-c-crud-title {
      width: 100%;
      margin-top: .25rem;

      input[type=text] {
        width: 100%;
        background-color: var(--primary);
        color: var(--primary-text);
        padding: .25rem;
        font-size: 1rem;
        border: 0px;
        height: 2rem;
        border-radius: 0px;
        outline: 0px;
        margin-top: .25rem;

        // make text tight to the input


        &:hover,
        &:focus {
          border: 0px;
          outline: 0px;
        }
      }



      textarea {
        width: 100%;
        background-color: var(--primary);
        color: var(--primary-text);
        padding: .25rem;
        font-size: 1rem;
        border: 0px;
        border-radius: 0px;
        outline: 0px;
        margin-top: .25rem;
        max-height: 100%;
        font-weight: 400 !important;
        // also, make it not resizable bu the user adding a scrollbar if needed
        resize: none;


        &:hover,
        &:focus {
          border: 0px;
          outline: 0px;
        }
      }
    }

    .sending-overlay {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, .5);
      color: white;
      position: absolute;
      top: 0px;
      left: 0px;
    }

  }

  .athx-c-crud-footer {
    height: 2rem;
    width: 100%;
    display: flex;
    justify-content: end;
    gap: .5rem;


    button {
      height: 2rem;
      border-radius: .125rem;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
      color: var(--primary-text);
      display: flex;
      align-items: center;
      justify-content: start;
      border-radius: .25rem;

    }
  }
}

.athx-c-events {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .athx-c-e-header {
    height: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;


    .athx-c-e-title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .25rem;
    }

    .athx-c-e-controllers {
      height: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: .25rem;


      button {
        height: 2rem;
        border-radius: .125rem;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        color: var(--primary-text);
        display: flex;
        align-items: center;
        justify-content: start;
        border-radius: .25rem;

        border: 2px solid var(--accent);

        &:hover {
          background-color: var(--accent);
          color: var(--accent-text);
        }
      }


    }

  }

  .athx-c-e-body {
    width: 100%;
    height: calc(100% - 2rem - .25rem);
    display: flex;
    flex-direction: column;
    margin-top: .25rem;
    background-color: var(--primary-light);

    &:not(:has(.athx-c-e-list)) {
      display: flex;
      justify-content: center;
      align-items: center;

      >h6 {
        color: var(--primary-text);
        margin: 0px;
        padding: 0px;
        font-size: 1rem;
        text-align: center;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    &:has(.athx-c-e-list) h6 {
      display: none;
    }


    .athx-c-e-list {
      padding: 0;
      list-style: none;
      margin: 0;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;

      li {
        display: flex;
        flex-direction: row;
        padding: 0;
        height: 3rem;
        width: 100%;
        position: relative;
        border-bottom: 1px solid var(--interactive);

        &:not(:last-child) {
          margin-bottom: .125rem;
        }

        &::after {
          content: '';
          position: absolute;
          height: 3px;
          bottom: 0px;
          left: 50%;
          width: 0%;
          background-color: transparent;
          transition: all 0.3s ease-in-out;
          background-color: var(--accent);
        }

        &:hover {
          border-bottom: 0px;
        }

        &:hover::after {
          width: 100%;
          left: 0px;

        }

        .head {
          padding-left: .25rem;
          width: calc(100% - 3rem);
          display: flex;
          justify-content: start;
          flex-direction: column;

          .title {
            font-size: 1rem;
            font-weight: 500;
            color: var(--primary-text);
            display: flex;
            flex-direction: row;
            align-items: center;

            .time {
              font-size: .75rem;
              color: inherit;
              margin-right: .25rem;
              height: 100%;
              border: 1px solid var(--accent);
              border-radius: .25rem;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              padding-left: .25rem;
              padding-right: .25rem;
            }
          }

          .description {
            font-size: .75rem;
            color: inherit;
          }
        }

        .control {
          width: 3rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            text-transform: none;
            color: var(--primary-text);
            background-color: transparent;
            border: 0px;
            outline: 0px;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
              border: 0px;
              outline: none;
            }

            &:active {
              transform: scale(.9);
            }

            &:hover {
              background-color: var(--accent);
              color: var(--accent-text);
            }

          }
        }
      }
    }
  }


}

.timepicker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-selector {
  display: flex;
  align-items: center;
  margin: 20px;
}

.time-dropdown {
  font-size: 18px;
  padding: 5px;
  margin: 0 5px;
}

.ampm-selector {
  margin: 20px;
}

.ampm-button {
  margin: 0 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.ampm-button.active {
  background-color: #0288d1;
  color: white;
}

.selected-time {
  margin-top: 20px;
  font-size: 18px;
}