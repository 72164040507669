input[disabled],
input.disabled,
textarea[disabled],
textarea.disabled,
button[disabled],
button.disabled,
a[disabled],
a.disabled,
select[disabled],
select.disabled,
.not-form-control.disabled {
  cursor: not-allowed !important;
  // opacity: .5 !important;
  position: relative !important;
  touch-action: none !important;
}