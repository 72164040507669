.athx-window {
  .athx-window-header {
    width: 100%;
    height: 30px;
    max-height: 30px;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: var(--athx-body-border-radius);
    border-top-right-radius: var(--athx-body-border-radius);
    border: 1px solid var(--athx-window-border-color);
    margin: 0px;
    padding: 0px;
    background-color: var(--primary);
    border: 1px solid var(--primary-light);


    h4.athx-window-title {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: 400;
      color: var(--primary-text);
      width: calc(100% - 90px);
      height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;


      &:active {
        cursor: move;
      }

    }

    div.athx-window-actions {
      width: 120px;
      height: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0px;
      padding: 0px;

      .athx-window-fixed-menu {
        position: relative;
        width: 30px;
        height: 100%;
        margin: 0px;
        padding: 0px;


        .athx-window-fixed-positions {
          position: absolute;
          top: 100%;
          right: -30px;
          height: 370px;
          overflow-y: auto;
          width: 300px;
          background-color: var(--primary-light);
          border-radius: var(--athx-border-radius);
          box-shadow: 0px 0px 10px rgba(var(--accent-rgb), .2);
          z-index: 5000;
          display: flex;

          .athx-window-positions-container {
            width: 100%;
            height: 1000px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(16, 1fr);
            gap: .5rem;
            padding: .5rem;

            button.athx-window-fixed-position {
              cursor: pointer;

              background-color: var(--primary);
              color: var(--primary-text);
              box-shadow: 0px 0px 5px rgba(var(--primary-rgb-dark), .5);
              position: relative;
              border: 0px;
              &.transparent {
                background-color: transparent;
                box-shadow: none;
                cursor: default;
              }

              &:hover:not(.transparent) {
                box-shadow: 0px 0px 5px rgba(var(--accent-rgb), .5) !important;
              }


              &.s,
              &.c,
              &.e,
              &.ts50,
              &.te50,
              &.bs50,
              &.be50,
              &.s50,
              &.e50,
              &.e50,
              &.ts33,
              &.tc33,
              &.te33,
              &.cs33,
              &.cc33,
              &.ce33,
              &.bs33,
              &.bc33,
              &.be33,
              &.t50-100,
              &.t33-100,
              &.c33-100,
              &.b33-100,
              &.w25-s25,
              &.w25-e25,
              &.s25,
              &.c50,
              &.e25,
              &.ts66,
              &.tc66,
              &.te66,
              &.bs66,
              &.bc66,
              &.be66,

              &.w66s,
              &.w66e,


              &.w66h66ts,
              &.w66h66te,
              &.w66h66be,
              &.w66h66bs,


              &.w66h33st,
              &.w66h33sc,
              &.w66h33sb,
              &.w66h33et,
              &.w66h33ec,
              &.w66h33eb,


              &.b50-100 {

                &.fsm::after {
                  font-size: 6pt !important;
                  line-height: 6pt !important;
                  text-align: center;
                  align-content: center;
                }

                &::after {
                  position: absolute;
                  content: attr(data-pos);
                  font-size: 7pt;
                  line-height: 7pt;
                  text-align: center;
                  align-content: center;
                  background-color: var(--interactive);
                  color: var(--interactive-text);
                  box-shadow: 0px 0px 5px rgba(var(--interactive-rgb-light), .2);

                }
              }

              &.s::after {
                top: 2%;
                left: 2%;
                width: calc(96%/3);
                height: 96%;
              }

              &.c::after {
                top: 2%;
                left: calc(100%/3 + 2%);
                width: calc(96%/3);
                height: 96%;
              }

              &.e::after {
                top: 2%;
                right: 2%;
                width: calc(96%/3);
                height: 96%;
              }

              &.ts50::after {
                top: 2%;
                left: 2%;
                width: calc(96%/2);
                height: calc(96%/2);
              }

              &.te50::after {
                top: 2%;
                right: 2%;
                width: calc(96%/2);
                height: calc(96%/2);
              }

              &.bs50::after {
                bottom: 2%;
                left: 2%;
                width: calc(96%/2);
                height: calc(96%/2);
              }

              &.be50::after {
                bottom: 2%;
                right: 2%;
                width: calc(96%/2);
                height: calc(96%/2);
              }

              &.s50::after {
                top: 2%;
                left: 2%;
                height: 96%;
                width: calc(96% / 2);
              }

              &.e50::after {
                top: 2%;
                right: 2%;
                height: 96%;
                width: calc(96% / 2);
              }

              &.ts33::after {
                top: 2%;
                left: 2%;
                width: calc(96%/3);
                height: calc(96%/3);
              }

              &.tc33::after {
                top: 2%;
                left: calc(100%/3 + 2%);
                width: calc(96%/3);
                height: calc(96%/3);
              }

              &.te33::after {
                top: 2%;
                right: 2%;
                width: calc(96%/3);
                height: calc(96%/3);
              }




              &.cs33::after {
                top: calc(100%/3 + 2%);
                left: 2%;
                width: calc(96%/3);
                height: calc(96%/3);
              }

              &.cc33::after {
                top: calc(100%/3 + 2%);
                left: calc(100%/3 + 2%);
                width: calc(96%/3);
                height: calc(96%/3);
              }

              &.ce33::after {
                top: calc(100%/3 + 2%);
                right: 2%;
                width: calc(96%/3);
                height: calc(96%/3);
              }







              &.bs33::after {
                bottom: 2%;
                left: 2%;
                width: calc(96%/3);
                height: calc(96%/3);
              }

              &.bc33::after {
                bottom: 2%;
                left: calc(100%/3 + 2%);
                width: calc(96%/3);
                height: calc(96%/3);
              }

              &.be33::after {
                bottom: 2%;
                right: 2%;
                width: calc(96%/3);
                height: calc(96%/3);
              }



              &.t33-100::after {
                width: 96%;
                left: 2%;
                top: 2%;
                height: calc(96%/3);
              }

              &.c33-100::after {
                width: 96%;
                left: 2%;
                top: calc(100%/3 + 2%);
                height: calc(96%/3);
              }

              &.b33-100::after {
                width: 96%;
                left: 2%;
                bottom: 2%;
                height: calc(96%/3);
              }


              &.t50-100::after {
                width: 96%;
                left: 2%;
                top: 2%;
                height: calc(96%/2);
              }

              &.b50-100::after {
                width: 96%;
                left: 2%;
                bottom: 2%;
                height: calc(96%/2);
              }



              &.s25::after {
                top: 2%;
                height: 100%;
                width: calc(96%/4);
                left: 2%;
              }

              &.c50::after {
                top: 2%;
                height: 100%;
                width: calc(96%/2);
                left: calc((100%/4) + 2%);
              }

              &.e25::after {
                top: 2%;
                height: 100%;
                width: calc(96%/4);
                right: 2%;
              }


              &.w25-s25::after {
                width: calc(100%/4);
                left: 27%;
                height: 96%;
                top: 2%;
              }

              &.w25-e25::after {
                width: calc(100%/4);
                left: 52%;
                height: 96%;
                top: 2%;
              }



              &.ts66::after {
                top: 2%;
                left: 2%;
                width: calc(96%/3);
                height: calc((96%/3)*2);
              }

              &.tc66::after {
                top: 2%;
                left: calc((100%/3) + 2%);
                width: calc(96%/3);
                height: calc((96%/3)*2);
              }

              &.te66::after {
                top: 2%;
                left: calc(((96%/3)*2) + 2%);
                width: calc(96%/3);
                height: calc((96%/3)*2);
              }

              &.bs66::after {
                top: calc((96%/3));
                left: 2%;
                width: calc(96%/3);
                height: calc((96%/3)*2);
              }

              &.bc66::after {
                top: calc((96%/3));
                left: calc((100%/3) + 2%);
                width: calc(96%/3);
                height: calc((96%/3)*2);
              }

              &.be66::after {
                top: calc((96%/3));
                left: calc(((96%/3)*2) + 2%);
                width: calc(96%/3);
                height: calc((96%/3)*2);
              }

              &.w66s::after {
                width: calc((96%/3)*2);
                height: 96%;
                top: 2%;
                left: 2%;
              }

              &.w66e::after {
                width: calc((96%/3)*2);
                height: 96%;
                top: 2%;
                left: calc(((96%/3)) + 2%);
              }


              &.w66h66ts::after {
                width: calc((96%/3)*2);
                height: calc((96%/3)*2);
                left: 2%;
                top: 2%;
              }

              &.w66h66te::after {
                width: calc((96%/3)*2);
                height: calc((96%/3)*2);
                right: 2%;
                top: 2%;
              }

              &.w66h66bs::after {
                width: calc((96%/3)*2);
                height: calc((96%/3)*2);
                right: 2%;
                bottom: 2%;
              }

              &.w66h66be::after {
                width: calc((96%/3)*2);
                height: calc((96%/3)*2);
                left: 2%;
                bottom: 2%;
              }


              &.w66h33st::after {
                width: calc((96%/3)*2);
                height: calc((96%/3) + 2%);
                left: 2%;
                top: 2%;
              }

              &.w66h33sc::after {
                width: calc((96%/3)*2);
                height: calc((96%/3) + 2%);
                left: 2%;
                top: calc((96%/3) + 2%);
              }

              &.w66h33sb::after {
                width: calc((96%/3)*2);
                height: calc((96%/3) + 2%);
                left: 2%;
                bottom: 2%;
              }


              &.w66h33et::after {
                width: calc((96%/3)*2);
                height: calc((96%/3) + 2%);
                right: 2%;
                top: 2%;
              }

              &.w66h33ec::after {
                width: calc((96%/3)*2);
                height: calc((96%/3) + 2%);
                right: 2%;
                top: calc((96%/3) + 2%);
              }

              &.w66h33eb::after {
                width: calc((96%/3)*2);
                height: calc((96%/3) + 2%);
                right: 2%;
                bottom: 2%;
              }



            }
          }



        }

      }

      .athx-window-action {
        width: 30px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color .3s;
        border: 0px;
        background-color: transparent;
        color: var(--primary-text);

        &.d {
          border-top-right-radius: var(--athx-border-radius);

        }

        &.f.active {
          background-color: var(--interactive);
          color: var(--interactive-text);
        }



        &:hover {
          --h-b-c: var(--secondary-rgb);
          --h-c: var(--secondary-text);

          &.d {
            --h-b-c: var(--error-rgb-light);
            --h-c: var(--error-light-text);
          }

          background-color: rgba(var(--h-b-c), .7);
          color: var(--h-c);
        }
      }
    }
  }
}