.athm-search-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;


  .athx-search-input-container {
    width: 100%;
    height: 2rem;
    padding: .25rem;
    background-color: var(--primary);
    
    color: var(--primary-text);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: calc(calc(var(--athenorix-menu-border-radius) - var(--athenorix-menu-padding)) - .25rem);

    &>* {
      transition: all .25s ease-in-out;
    }

    .icon-glass {
      display: inline-flex;
      width: 2rem;
      height: 2rem;
      align-items: center;
      justify-content: center;
    }


    // &:has(input[type=search][name=global_search]:where(:focus, :hover, :active)) {
    //   .icon-glass {
    //     opacity: 0;
    //     width: 0px;
    //     height: 0px;
    //   }
    // }

    form {
      width: 100%;
      height: 100%;
      padding: 0px;
      margin: 0px;

      input[type=search][name=global_search] {
        height: 1.5rem;
        background-color: transparent;
        border: 0px;
        color: var(--primary-text);
        outline: none;
        width: 100%;


        &::-webkit-search-cancel-button {
          appearance: none;
          background-color: var(--accent);
          border: 0px;
          cursor: pointer;
          height: 16px;
          width: 16px;
          mask: url('../../../../../public/form-elements/input/search-cross.svg');
          mask-size: cover;
          -webkit-mask: url('../../../../../public/form-elements/input/search-cross.svg');
          -webkit-mask-size: cover;
        }


        &:focus,
        &:hover,
        &:active {
          background-color: transparent;
          color: var(--primary-text);
          outline: none;
          border: 0px;
          border-bottom: 1px solid rgba(var(--accent-rgb), .5);
        }
      }
    }
  }

  .athx-search-results-container {
    width: 100%;
    height: calc(100% - 2rem - .25rem);
    margin: 0px;
    margin-top: .25rem;
    padding: 0;
    overflow-y: auto;

    .athx-search-result {
      width: 100%;
      height: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      background-color: var(--primary);
      color: var(--primary-text);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        transition: all .25s ease-in-out;
        bottom: 0px;
        left: 50%;
      }

      &:hover::after {
        width: 100%;
        left: 0px;
        background-color: var(--accent);
      }

      &:not(:first-child) {
        margin-top: .125rem;
      }

      &:not(:last-child) {
        .aths-sr-b {
          border-bottom: 1px solid rgba(var(--accent-rgb), .5);
        }
      }

      .athx-sr-b {
        padding: .25rem;
        width: calc(100% - 3rem);
        display: flex;
        flex-direction: row;

        .athx-sr-i {
          width: 2rem;
          margin-right: .25rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .athx-sr-n {

          width: calc(100% - 3rem);
          display: flex;
          flex-direction: column;


          .title {
            width: 100%;
          }

          .description {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 9pt;
          }
        }

      }

      .athx-sr-a {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;


        &:hover {
          background-color: var(--accent);
          color: var(--accent-text);
        }

      }
    }


  }

}