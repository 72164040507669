.form-element-container:not(:has(input[type="checkbox"])):not(:has(input[type="radio"])) {
  display: block;
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 0px;
  transition: all 0.3s ease-in-out;
  padding: 0px;


  &>*::placeholder {
    color: transparent;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  &:has(input[type=text]) {
    box-shadow: 0px 0px 5px 0px var(--primary);
  }

  textarea,
  input {
    font-family: 'poppins', sans-serif;
  }

  &:has(input) {
    height: 3rem;
  }


  input {
    width: 100%;
    height: 100%;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 0px;
    outline: none;
    z-index: 2;
    position: relative;
    border: 0px;

    &::placeholder {
      color: transparent;
    }

    padding-top: 1rem;
    padding-left: .25rem;
    font-size: 15pt;

    &:focus,
    &:hover,
    &:active {
      border-top: 0px;
      border: 0px;
      outline: none;
    }

    &.success,
    &.error {
      padding-left: 0;
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &.lowercase {
      text-transform: lowercase;
    }

    &.capitalize {
      text-transform: capitalize;
    }
  }

  textarea {
    width: 100%;
    height: 100%;
    min-height: 4.5rem;
    max-height: 15rem;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 0px;
    outline: none;
    z-index: 2;
    position: relative;
    border: 0px;
    margin: 0;
    padding-left: .40rem;
    resize: vertical;
    // letter-spacing: -.06rem;

    &::placeholder {
      color: transparent;
    }

    padding-top: 2.25rem;
    font-size: 15pt;

    &:focus,
    &:hover,
    &:active {
      border-top: 0px;
      border: 0px;
      outline: none;
    }

  }

  label {
    position: absolute;
    top: .25rem;
    left: .45rem;
    font-size: 21pt;
    color: var(--primary);
    transition: all 0.3s ease-in-out;
    padding-bottom: -2px;
    z-index: 2;
    opacity: .7;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &:hover {
      cursor: text;
    }
  }

  &:has(input:disabled) {
    label {
      cursor: not-allowed;
      pointer-events: none;
    }

    input {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 0%;
    height: 3px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    opacity: .5;
    z-index: 3;
  }

  &:has(input.error),
  &:has(input.success) {
    border-left-style: solid;
    border-left-width: 3px;

    &::after {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 3rem;
      height: 3rem;
      background-color: transparent;
      font-size: 25pt;
      margin-left: .5rem;
      transition: all 0.3s ease-in-out;
      opacity: 1;
      z-index: 4;
    }
  }

  &:has(input.success) {
    border-left-color: var(--success);

    &::after {
      color: var(--success);
      content: '✓';
    }
  }

  &:has(input.error) {
    border-left-color: var(--error);

    &::after {
      color: var(--error);
      content: '✗';
    }
  }

  &:has(input:focus),
  &:has(input:not(:placeholder-shown)) {
    label {
      top: -.25rem;
      font-size: 12pt;
      opacity: 1;
      color: var(--interactive)
    }
  }

  &:has(input.error),
  &:has(input.success) {
    padding-left: 3rem;

    label {
      left: 3rem;
    }
  }

  &:has(input:focus, input:not(:placeholder-shown))::before {
    width: 100%;
    left: 0px;
    opacity: 1;
    background-color: var(--interactive);

    &:hover {
      background-color: var(--interactive-dark);
    }
  }

  &:has(input.error)::before {
    width: 100%;
    left: 0px;
    opacity: 1;
    background-color: var(--error);

    &:hover {
      background-color: var(--error-dark);
    }
  }

  &:has(input.success)::before {
    width: 100%;
    left: 0px;
    opacity: 1;
    background-color: var(--success);

    &:hover {
      background-color: var(--success-dark);
    }
  }

  // All edits to the container in cashe there is an "textarea" element
  &:has(textarea.error),
  &:has(textarea.success) {
    border-right-style: solid;
    border-right-width: 3px;

    &::after {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 3rem;
      height: 3rem;
      background-color: transparent;
      font-size: 25pt;
      margin-left: .5rem;
      transition: all 0.3s ease-in-out;
      opacity: 1;
      z-index: 4;
    }
  }

  &:has(textarea.success) {
    border-right-color: var(--success);

    &::after {
      color: var(--success);
      content: '✓';
    }
  }

  &:has(textarea.error) {
    border-left-color: var(--error);

    &::after {
      color: var(--error);
      content: '✗';
    }
  }

  &:has(textarea:focus),
  &:has(textarea:not(:placeholder-shown)) {
    label {
      top: -.25rem;
      font-size: 12pt;
      opacity: 1;
      color: var(--interactive)
    }
  }

  &:has(textarea:focus, textarea:not(:placeholder-shown))::before {
    width: 100%;
    left: 0px;
    opacity: 1;
    background-color: var(--interactive);
  }

  &:has(textarea.error)::before {
    width: 100%;
    left: 0px;
    opacity: 1;
    background-color: var(--error);
  }

  &:has(textarea.success)::before {
    width: 100%;
    left: 0px;
    opacity: 1;
    background-color: var(--success);
  }

  &:not(:has(input.error)):not(:has(input.success)):not(:has(textarea)) {

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 100%;
      opacity: .5;
      z-index: 5;
    }

    input {
      padding-left: 3rem;
    }

    label {
      left: 3rem;
    }

    &:has(input[type=search])::after {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNi42NzI1IDE2LjY0MTJMMjEgMjFNMTkgMTFDMTkgMTUuNDE4MyAxNS40MTgzIDE5IDExIDE5QzYuNTgxNzIgMTkgMyAxNS40MTgzIDMgMTFDMyA2LjU4MTcyIDYuNTgxNzIgMyAxMSAzQzE1LjQxODMgMyAxOSA2LjU4MTcyIDE5IDExWiIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+);
      background-repeat: no-repeat;
      background-size: 85%;
      background-position: center;
    }

    &:has(input[type=text])::after {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik04IDRWMjBNMTcgMTJWMjBNNiAyMEgxME0xNSAyMEgxOU0xMyA3VjRIM1Y3TTIxIDE0VjEySDEzVjE0IiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4=);
      background-repeat: no-repeat;
      background-size: 85%;
      background-position: center;
    }

    &:has(input[type=number])::after {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIEdlbmVyYXRvcjogU1ZHIFJlcG8gTWl4ZXIgVG9vbHMgLS0+Cjxzdmcgd2lkdGg9IjgwMHB4IiBoZWlnaHQ9IjgwMHB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cg08ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgoNPHBhdGggZD0ibTAgMGgzMnYzMmgtMzJ6Ii8+Cg08cGF0aCBkPSJtMjguNSAzMmgtMjV2LTMyaDI1em0tMi0yM2gtMjF2MjFoMjF6bS0xNSAxNXYzaC0zdi0zem02IDB2M2gtM3YtM3ptNiAwdjNoLTN2LTN6bS0xMi02djNoLTN2LTN6bTYgMHYzaC0zdi0zem02IDB2M2gtM3YtM3ptLTEyLTZ2M2gtM3YtM3ptNiAwdjNoLTN2LTN6bTYgMHYzaC0zdi0zem0zLTEwaC0yMXY1aDIxeiIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIi8+Cg08L2c+Cg08L3N2Zz4=);
      background-repeat: no-repeat;
      background-size: 85%;
      background-position: center;
    }


    &:has(input:focus, input:not(:placeholder-shown)) {
      &::after {
        display: none;
      }

      input {
        padding-left: .25rem;
      }

      label {
        left: .45rem;
      }
    }


  }
}

.form-element-container:has(input[type="checkbox"]),
.form-element-container:has(input[type="radio"]) {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 0px;
  transition: all 0.3s ease-in-out;
  color: var(--athx-window-body-color);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  height: calc(1.5rem + 1rem);
  padding: .5rem 0;
  width: 100%;


  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 0%;
    height: 3px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    opacity: .5;
    z-index: 3;
  }

  --theme-color: var(--primary);

  &:has(input.accent) {
    --theme-color: var(--accent);
  }

  &.accent {
    --theme-color: var(--accent);
  }

  &:has(input.secondary) {
    --theme-color: var(--secondary);
  }

  &:has(input.interactive) {
    --theme-color: var(--interactive);
  }

  &:has(input.error) {
    --theme-color: var(--error);
  }

  &:has(input.success) {
    --theme-color: var(--success);
  }

  &:has(input.warning) {
    --theme-color: var(--warning);
  }

  &:has(input.info) {
    --theme-color: var(--info);
  }

  &:hover::before,
  &:has(input:checked)::before {
    width: 100%;
    left: 0px;
    background-color: var(--theme-color);
  }


  label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18pt;
    padding: 0px;
    margin: 0px;



    input[type="checkbox"],
    input[type="radio"] {
      display: none;
      outline: none;
    }


    &:hover,
    &:focus,
    &:active {

      span.input-checkbox,
      span.input-radio {
        outline: none;
        box-shadow: 0px 0px 3px 1px var(--theme-color), 0px 0px 3px 1px var(--theme-color) inset;
      }
    }
  }

  span.input-radio {
    border-radius: 50%;
  }

  span.input-checkbox,
  span.input-radio {
    --bg-color: white;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .5rem;
    box-shadow: none;
    border: 1px solid var(--theme-color);
    position: relative;

    &.primary {
      --bg-color: var(--primary);
    }

    &.accent {
      --bg-color: var(--accent);
    }

    &.secondary {
      --bg-color: var(--secondary);
    }

    &.interactive {
      --bg-color: var(--interactive);
    }

    &.error {
      --bg-color: var(--error);
    }

    &.success {
      --bg-color: var(--success);
    }

    &.warning {
      --bg-color: var(--warning);
    }

    &.info {
      --bg-color: var(--info);
    }

    background-color: var(--bg-color);


    &.lg {
      width: 2rem !important;
      height: 2rem !important;
    }

    &.sm {
      width: 1rem !important;
      height: 1rem !important;
    }



    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease-in-out;
      z-index: 3;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  span.input-radio::after {
    border-radius: 50%;
  }

  &:has(input:checked) {


    span.input-checkbox::after {
      background-color: var(--theme-color);
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjEuNXJlbSIgd2lkdGg9IjEuNXJlbSIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiANCgkgdmlld0JveD0iMCAwIDEyOC40MTEgMTI4LjQxMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBvbHlnb24gcG9pbnRzPSIxMjcuNTI2LDE1LjI5NCA0NS42NjUsNzguMjE2IDAuODYzLDQyLjg2MSAwLDU5LjI1NSA0NC40NzksMTEzLjExNyAxMjguNDExLDMxLjY2NiAJCSIvPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg==);
      background-size: 95%;
    }

    span.input-radio::after {
      background-color: var(--theme-color);
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjEuNXJlbSIgd2lkdGg9IjEuNXJlbSIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiANCgkgdmlld0JveD0iMCAwIDEyOC40MTEgMTI4LjQxMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBvbHlnb24gcG9pbnRzPSIxMjcuNTI2LDE1LjI5NCA0NS42NjUsNzguMjE2IDAuODYzLDQyLjg2MSAwLDU5LjI1NSA0NC40NzksMTEzLjExNyAxMjguNDExLDMxLjY2NiAJCSIvPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg==);
      background-size: 65%;
    }
  }

  &:has(input:indeterminate) {
    span.input-checkbox::after {
      background-color: var(--theme-color);
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgZmlsbD0iI2ZmZmZmZiIgd2lkdGg9IjEuNXJlbSIgaGVpZ2h0PSIxLjVyZW0iIHZpZXdCb3g9IjAgMCAxOTIwIDE5MjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTI4OCA4NjR2MTkyaDEzNDRWODY0eiIgZmlsbC1ydWxlPSJldmVub2RkIi8+DQo8L3N2Zz4=);
      background-size: 95%;
    }

    span.input-radio::after {
      background-color: var(--theme-color);
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgZmlsbD0iI2ZmZmZmZiIgd2lkdGg9IjEuNXJlbSIgaGVpZ2h0PSIxLjVyZW0iIHZpZXdCb3g9IjAgMCAxOTIwIDE5MjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTI4OCA4NjR2MTkyaDEzNDRWODY0eiIgZmlsbC1ydWxlPSJldmVub2RkIi8+DQo8L3N2Zz4=);
      background-size: 100%;
    }

  }
}