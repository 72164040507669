@import '../sass-variables.scss';

.athenorix-menu {
  --athenorix-menu-height: 30rem;
  --athenorix-menu-width: 25rem;
  --athenorix-menu-bottom-space: 4rem;
  --athenorix-menu-left-space: .5rem;
  --athenorix-menu-top-space: .5rem;
  --athenorix-menu-border-radius: 1rem;
  --athenorix-menu-padding: .5rem;
  position: fixed;
  border-radius: var(--athenorix-menu-border-radius);
  box-shadow: 0px 0px .25rem .125rem var(--accent);

  min-height: var(--athenorix-menu-height);
  max-height: var(--athenorix-menu-height);
  background-color: var(--primary);
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  // display: none;
  flex-direction: column;
  padding: var(--athenorix-menu-padding);

  // cannot select anyting 
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;


  &.athenorix-menu-large {
    --athenorix-menu-width: 34rem;
    // top: 110%;
    top: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-height));
    left: var(--athenorix-menu-left-space);
    width: var(--athenorix-menu-width);
    height: var(--athenorix-menu-height);
  }

  &.athenorix-menu-middle {
    // top: 110%;
    top: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-height));
    right: var(--athenorix-menu-left-space);
    width: var(--athenorix-menu-width);
    height: var(--athenorix-menu-height);
  }

  &.athenorix-menu-small {
    --athenorix-menu-width: 20rem;
    // top: 110%;
    top: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-height));
    right: var(--athenorix-menu-left-space);
    width: var(--athenorix-menu-width);
    height: var(--athenorix-menu-height);
  }

  &.open {
    // top: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-height));
    opacity: 1;
    visibility: visible;
    // display: flex;

    &.fullscreen {
      top: var(--athenorix-menu-top-space) !important;
      left: var(--athenorix-menu-left-space) !important;
      bottom: var(--athenorix-menu-bottom-space) !important;
      right: var(--athenorix-menu-left-space) !important;
      width: calc(100% - var(--athenorix-menu-left-space) - var(--athenorix-menu-left-space)) !important;
      height: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-top-space)) !important;
      min-width: calc(100% - var(--athenorix-menu-left-space) - var(--athenorix-menu-left-space)) !important;
      min-height: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-top-space)) !important;
      max-width: calc(100% - var(--athenorix-menu-left-space) - var(--athenorix-menu-left-space)) !important;
      max-height: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-top-space)) !important;

      .athm-body {
        .athm-min-menu {
          max-width: 300px;
          margin-right: .5rem;
        }

        .athm-max-menu {
          display: flex;
          width: calc(100% - 300px);
          max-width: calc(100% - 300px);
        }
      }


    }
  }



  .athm-header {
    width: 100%;
    height: 2rem;
    border-radius: calc(var(--athenorix-menu-border-radius) - var(--athenorix-menu-padding));
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
    background-color: var(--primary-light);
    padding: .25rem;
    margin-bottom: .5rem;

    .athm-title {
      color: var(--primary-light-text);
      font-weight: 600;
      text-align: start;
      font-size: 1.2em;

    }

    .athm-fullscreen {
      height: calc(2rem - .5rem);
      width: calc(2rem - .5rem);
      border-radius: .25rem;
      margin-right: .5rem;
      border: 0;
      background-color: transparent;
      color: var(--primary-light-text);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0;

      &:focus,
      &:hover {
        outline: none;
        border: 0;
        background-color: var(--primary);
        color: var(--primary-text)
      }

      &:active,
      &.active {
        background-color: var(--primary-dark);
        color: var(--primary-dark-text);
        outline: none;
        border: 0;
      }

    }

    .athm-touch-closer {
      display: none;
      position: absolute;
      width: 3rem;
      height: 1rem;
      left: calc(50% - 1.5rem);
      top: -.75rem;
      justify-content: center;
      align-items: center;
      background-color: var(--primary);
      color: var(--primary-light-text);
      border: 1px solid var(--primary-dark);
      border-radius: .25rem;

      &:focus,
      &:active,
      &:hover {
        outline: none;
        box-shadow: 0px 0px 2px 1px var(--accent);

      }

    }

  }

  .athm-body {
    border-radius: calc(var(--athenorix-menu-border-radius) - var(--athenorix-menu-padding));
    background-color: var(--primary-light);
    padding: .25rem;
    height: calc(100% - 2rem - var(--athenorix-menu-padding));
    display: flex;
    justify-content: start;
    flex-direction: row;

    .athm-min-menu {
      width: 100%;
    }

    .athm-max-menu {
      display: none;
      background-color: var(--primary);
      border-radius: calc((var(--athenorix-menu-border-radius) - var(--athenorix-menu-padding)) - .25rem);
    }

  }
}

// // on low performance devices
// @supports (overflow: overlay) {
//   .athenorix-menu {
//     overflow: overlay;
//   }
// }

// @supports not (overflow: overlay) {
//   .athenorix-menu {
//     overflow: auto;
//   }
// }

html[athx-device=mobile] {
  .athenorix-menu {
    --athenorix-menu-width: calc(100% - var(--athenorix-menu-left-space) - var(--athenorix-menu-left-space));
    --athenorix-menu-height: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-top-space));
  }

  .athm-touch-closer {
    display: flex !important;
  }
}


@media screen and (min-width: 992px) {
  
  html[athx-device=mobile] .athm-body {
    .athm-min-menu {
      max-width: 300px;
      margin-right: .5rem;
    }

    .athm-max-menu {
      display: flex !important;
      width: calc(100% - 300px);
      max-width: calc(100% - 300px);
    }
  }
  
}

@media screen and (max-width: 576px) {
  .athenorix-menu {
    --athenorix-menu-width: calc(100% - var(--athenorix-menu-left-space) - var(--athenorix-menu-left-space));
  }
}

@media screen and (max-height: 576px) {
  .athenorix-menu {
    --athenorix-menu-height: calc(100% - var(--athenorix-menu-bottom-space) - var(--athenorix-menu-top-space));
  }
}