.athx-window .grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(12, 1fr);

  &.gap-0 {
    gap: 0;
  }

  &.gap-1 {
    gap: .5rem;
  }

  &.gap-2 {
    gap: 1rem;
  }

  &.gap-3 {
    gap: 1.5rem;
  }

  &.gap-4 {
    gap: 2rem;
  }

  &.gap-5 {
    gap: 2.5rem;
  }
}

// .athx-window [class*="gc-"] {
//   display: grid;
//   grid-template-columns: repeat(1, 1fr);
// }


.athx-window[include*="-xs-"] .xs\:gc-1 { grid-column: span 1; }
.athx-window[include*="-xs-"] .xs\:gc-2 { grid-column: span 2;  }
.athx-window[include*="-xs-"] .xs\:gc-3 { grid-column: span 3;  }
.athx-window[include*="-xs-"] .xs\:gc-4 { grid-column: span 4;  }
.athx-window[include*="-xs-"] .xs\:gc-5 { grid-column: span 5;  }
.athx-window[include*="-xs-"] .xs\:gc-6 { grid-column: span 6;  }
.athx-window[include*="-xs-"] .xs\:gc-7 { grid-column: span 7; }
.athx-window[include*="-xs-"] .xs\:gc-8 { grid-column: span 8; }
.athx-window[include*="-xs-"] .xs\:gc-9 { grid-column: span 9; }
.athx-window[include*="-xs-"] .xs\:gc-10 { grid-column: span 10; }
.athx-window[include*="-xs-"] .xs\:gc-11 { grid-column: span 11; }
.athx-window[include*="-xs-"] .xs\:gc-12 { grid-column: span 12; }

.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-1 { grid-column: span 1; }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-2 { grid-column: span 2;  }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-3 { grid-column: span 3;  }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-4 { grid-column: span 4;  }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-5 { grid-column: span 5;  }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-6 { grid-column: span 6;  }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-7 { grid-column: span 7; }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-8 { grid-column: span 8; }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-9 { grid-column: span 9; }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-10 { grid-column: span 10; }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-11 { grid-column: span 11; }
.athx-window[include*="-sm-"][exclude*="-xs-"] .sm\:gc-12 { grid-column: span 12; }

.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-1 { grid-column: span 1; }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-2 { grid-column: span 2;  }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-3 { grid-column: span 3;  }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-4 { grid-column: span 4;  }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-5 { grid-column: span 5;  }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-6 { grid-column: span 6;  }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-7 { grid-column: span 7; }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-8 { grid-column: span 8; }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-9 { grid-column: span 9; }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-10 { grid-column: span 10; }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-11 { grid-column: span 11; }
.athx-window[include*="-md-"][exclude*="-sm-"] .md\:gc-12 { grid-column: span 12; }

.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-1 { grid-column: span 1; }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-2 { grid-column: span 2;  }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-3 { grid-column: span 3;  }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-4 { grid-column: span 4;  }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-5 { grid-column: span 5;  }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-6 { grid-column: span 6;  }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-7 { grid-column: span 7; }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-8 { grid-column: span 8; }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-9 { grid-column: span 9; }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-10 { grid-column: span 10; }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-11 { grid-column: span 11; }
.athx-window[include*="-lg-"][exclude*="-md-"] .lg\:gc-12 { grid-column: span 12; }

.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-1 { grid-column: span 1; }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-2 { grid-column: span 2;  }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-3 { grid-column: span 3;  }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-4 { grid-column: span 4;  }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-5 { grid-column: span 5;  }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-6 { grid-column: span 6;  }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-7 { grid-column: span 7; }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-8 { grid-column: span 8; }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-9 { grid-column: span 9; }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-10 { grid-column: span 10; }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-11 { grid-column: span 11; }
.athx-window[include*="-xl-"][exclude*="-lg-"] .xl\:gc-12 { grid-column: span 12; }

.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-1 { grid-column: span 1; }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-2 { grid-column: span 2;  }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-3 { grid-column: span 3;  }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-4 { grid-column: span 4;  }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-5 { grid-column: span 5;  }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-6 { grid-column: span 6;  }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-7 { grid-column: span 7; }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-8 { grid-column: span 8; }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-9 { grid-column: span 9; }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-10 { grid-column: span 10; }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-11 { grid-column: span 11; }
.athx-window[include*="-xxl-"][exclude*="-xl-"] .xxl\:gc-12 { grid-column: span 12; }



@each $size in xs, sm, md, lg, xl, xxl {
  @for $i from 1 through 12 {
    .workspace-window[include*="-#{$size}-"] .grid-#{$size}-col-#{$i} {
      grid-column: span #{$i} / span #{$i};
    }
  }
}