@import '../../../../sass-variables.scss';

ul.athm-process-list {

  --athm-process-list-width: 100%;
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: var(--athm-process-list-width);
  height: 100%;
  overflow-y: auto;

  li {
    width: 100%;
    height: 3.5rem;
    padding: 0px;
    margin-bottom: .25rem;
    display: flex;

    &:not(:first-child) {
      margin-top: .25rem;
    }

    &:not(:last-child) {
      margin-bottom: .25rem;
    }

    &>a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: .25rem;
      font-size: 14pt;
      text-decoration: none;
      text-transform: none;
      background-color: var(--primary);
      color: var(--primary-text);
      flex: 1 1 auto;
      margin: 0px;
      border-radius: calc((var(--athenorix-menu-border-radius) - var(--athenorix-menu-padding)) - .25rem);

      &:hover {
        box-shadow: inset 0px 0px 2px 1px var(--accent);
      }

      &:active {
        box-shadow: 0px 0px 2px 1px var(--accent-light);
        background-color: var(--primary-dark);
        color: var(--primary-dark-text);
      }

      .desc {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          max-width: calc(100% - .5rem);
          overflow: hidden;
          text-wrap: nowrap;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .foot {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;

        >div {
          width: 100%;
          height: 6px;
          border-radius: 3px;
          background-color: var(--primary-light);
          display: flex;

          >div {
            width:56%;
            height: 100%;
            border-radius: 3px;
            background-color: var(--interactive);
          }

        }
      }

    }
  }
}

