@import './src/header/index.scss';
@import './src/body/index.scss';
@import './src/body/grid.scss';
@import './src/footer/index.scss';


.athx-window-container {
  position: absolute;

  .athx-window {

    --athx-window-padding: 0px;
    --athx-border-radius: .5rem;
    --athx-header-border-radius: calc(var(--athx-border-radius) - var(--athx-window-padding));
    --athx-body-border-radius: calc(var(--athx-border-radius) - var(--athx-window-padding));
    --athx-window-border-color: var(--secondary-dark);


    .athx-window-locked {
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      display: flex;
    }

    &.fixed {
      --athx-border-radius: 0;

      .athx-window-locked {
        display: flex;
      }
    }

    &:not(.fixed) {
      .athx-window-locked {
        display: none;
      }
    }


    position: fixed;
    display: flex;
    flex-direction: column;
    border-radius: var(--athx-border-radius);
    background-color: var(--athx-window-body-background-color);
    color:var(--athx-window-body-color);
    padding: var(--athx-window-padding);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    min-width: 300px;
    min-height: 300px;



    &.fullscreen {
      --athx-border-radius: 0 !important;
      top: 0px !important;
      left: 0px !important;
      width: 100% !important;
      height: calc(100dvh - 3.5rem) !important;
    }

    // &.minimized {
    //   bottom: - 300px;
    //   /* fuori dallo schermo */
    //   left: calc(50dvw - 150px);
    //   /* centro orizzontale */
    //   width: 300px;
    //   height: 300px;
    //   opacity: 0;
    //   pointer-events: none;
    //   overflow: hidden;
    //   transition:
    //     transform 0.3s ease-in-out,
    //     opacity 0.3s ease-in-out;
    //   transform-origin: top left;
    //   transform: scale(0.01) translateY(500px);
    //   opacity: 0.2;
    //   pointer-events: none;
    // }


    &.active {
      box-shadow: 0px 0px 10px 3px rgba(var(--accent-rgb), .6);
    }





    .athx-window-resizer {
      opacity: 0 !important;
      background-color: var(--secondary-dark);
      position: absolute;
      border-radius: .5rem;


      &.t {
        top: 0px;
        left: 0px;
      }


      &.b {
        bottom: 0px;
        left: 0px;
      }

      &.t,
      &.b {
        width: 100%;
        height: .25rem;
        cursor: ns-resize;
      }


      &.a-tl,
      &.a-tr,
      &.a-bl,
      &.a-br {
        position: absolute;
        height: .25rem;
        width: .25rem;
        z-index: 2;

        &.a-tl {
          top: 0px;
          left: 0px;
          cursor: nw-resize;
        }

        &.a-tr {
          top: 0px;
          right: 0px;
          cursor: ne-resize;
        }

        &.a-bl {
          bottom: 0px;
          left: 0px;
          cursor: sw-resize;
        }

        &.a-br {
          bottom: 0px;
          right: 0px;
          cursor: se-resize;
        }
      }


    }


    .athx-window-menu {
      --athx-window-menu-height: 1.75rem;
      width: 100%;
      margin: 0px;
      padding: 0px;
      display: block;
      border-bottom: 1px solid var(--athx-window-border-color);
      background-color: rgba(var(--primary-rgb-light), .7);


      .athx-window-menu-content {
        flex-wrap: wrap;
        display: flex;
        justify-content: start;
        align-items: start;
        max-width: 100%;
        width: 100%;
        height: 100%;





        &>*:not(:last-child) {
          border-right: 1px solid silver !important;
        }

        .athx-window-menu-item {

          // text only in one line
          white-space: nowrap;

          --athx-window-menu-item-theme: var(--interactive-rgb);
          --athx-window-menu-item-text: var(--interactive-rgb-text);
          --athx-window-menu-item-theme-light: var(--interactive-rgb-light);
          --athx-window-menu-item-text-light: var(--interactive-rgb-light-text);
          --athx-window-menu-item-theme-dark: var(--interactive-rgb-dark);
          --athx-window-menu-item-text-dark: var(--interactive-rgb-dark-text);

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: var(--athx-window-menu-height);
          border: 0px;
          padding: 0.25 .5rem;
          margin: 0px;
          border-radius: 0px;
          font-size: 1rem;
          letter-spacing: .075rem;
          outline: none;
          cursor: pointer;



          background-color: rgb(var(--athx-window-menu-item-theme));
          color: rgb(var(--athx-window-menu-item-text));

          &.error {
            --athx-window-menu-item-theme: var(--error-rgb);
            --athx-window-menu-item-text: var(--error-rgb-text);
            --athx-window-menu-item-theme-light: var(--error-rgb-light);
            --athx-window-menu-item-text-light: var(--error-rgb-light-text);
            --athx-window-menu-item-theme-dark: var(--error-rgb-dark);
            --athx-window-menu-item-text-dark: var(--error-rgb-dark-text);
          }

          &.success {
            --athx-window-menu-item-theme: var(--success-rgb);
            --athx-window-menu-item-text: var(--success-rgb-text);
            --athx-window-menu-item-theme-light: var(--success-rgb-light);
            --athx-window-menu-item-text-light: var(--success-rgb-light-text);
            --athx-window-menu-item-theme-dark: var(--success-rgb-dark);
            --athx-window-menu-item-text-dark: var(--success-rgb-dark-text);
          }

          &.warning {
            --athx-window-menu-item-theme: var(--warning-rgb);
            --athx-window-menu-item-text: var(--warning-rgb-text);
            --athx-window-menu-item-theme-light: var(--warning-rgb-light);
            --athx-window-menu-item-text-light: var(--warning-rgb-light-text);
            --athx-window-menu-item-theme-dark: var(--warning-rgb-dark);
            --athx-window-menu-item-text-dark: var(--warning-rgb-dark-text);
          }

          &.primary {
            --athx-window-menu-item-theme: var(--primary-rgb);
            --athx-window-menu-item-text: var(--primary-rgb-text);
            --athx-window-menu-item-theme-light: var(--primary-rgb-light);
            --athx-window-menu-item-text-light: var(--primary-rgb-light-text);
            --athx-window-menu-item-theme-dark: var(--primary-rgb-dark);
            --athx-window-menu-item-text-dark: var(--primary-rgb-dark-text);
          }

          &.accent {
            --athx-window-menu-item-theme: var(--accent-rgb);
            --athx-window-menu-item-text: var(--accent-rgb-text);
            --athx-window-menu-item-theme-light: var(--accent-rgb-light);
            --athx-window-menu-item-text-light: var(--accent-rgb-light-text);
            --athx-window-menu-item-theme-dark: var(--accent-rgb-dark);
            --athx-window-menu-item-text-dark: var(--accent-rgb-dark-text);
          }

          &.secondary {
            --athx-window-menu-item-theme: var(--secondary-rgb);
            --athx-window-menu-item-text: var(--secondary-rgb-text);
            --athx-window-menu-item-theme-light: var(--secondary-rgb-light);
            --athx-window-menu-item-text-light: var(--secondary-rgb-light-text);
            --athx-window-menu-item-theme-dark: var(--secondary-rgb-dark);
            --athx-window-menu-item-text-dark: var(--secondary-rgb-dark-text);
          }

          &.info {
            --athx-window-menu-item-theme: var(--info-rgb);
            --athx-window-menu-item-text: var(--info-rgb-text);
            --athx-window-menu-item-theme-light: var(--info-rgb-light);
            --athx-window-menu-item-text-light: var(--info-rgb-light-text);
            --athx-window-menu-item-theme-dark: var(--info-rgb-dark);
            --athx-window-menu-item-text-dark: var(--info-rgb-dark-text);
          }

          &.interactive {
            --athx-window-menu-item-theme: var(--interactive-rgb);
            --athx-window-menu-item-text: var(--interactive-rgb-text);
            --athx-window-menu-item-theme-light: var(--interactive-rgb-light);
            --athx-window-menu-item-text-light: var(--interactive-rgb-light-text);
            --athx-window-menu-item-theme-dark: var(--interactive-rgb-dark);
            --athx-window-menu-item-text-dark: var(--interactive-rgb-dark-text);
          }


          &:hover,
          &:focus,
          &:active {
            border: 0px;
            outline: none;
          }

          &:hover,
          &:focus {
            background-color: rgb(var(--athx-window-menu-item-theme-light));
            color: rgb(var(--athx-window-menu-item-text-light));
          }

          &:active {
            background-color: rgb(var(--athx-window-menu-item-theme-dark));
            color: rgb(var(--athx-window-menu-item-text-dark));
          }

          &.close {
            margin-left: auto;
            margin-right: 5px;
          }

          &>svg,
          &>img {
            margin-right: .5rem;
          }

          &>img {
            max-width: 2.3rem;
            max-height: 2.3rem;
          }
        }
      }
    }

  }
}


.athx-window-placeholders {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100dvh - 3.5rem);
  background-color: transparent;
  display: none;

  &.dragging {
    display: block;
  }

  div {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0px;
    padding: 0px;

    &>* {
      position: absolute;
      border-radius: 0;
      background-color: rgba(var(--interactive-rgb), .5);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      display: none;

      &.active {
        display: block;
      }
    }


    .top {
      width: calc(100% - 10px);
      height: calc(100dvh - 3.5rem - 10px);
      top: 5px;
      left: 5px;
    }

    .left {
      width: calc(50% - 10px);
      height: calc(100dvh - 3.5rem - 10px);
      left: 5px;
      top: 5px;
    }

    .top-left {
      width: calc(50% - 10px);
      height: calc((100dvh - 3.5rem - 10px) / 2);
      left: 5px;
      top: 5px;
    }

    .bottom-left {
      width: calc(50% - 10px);
      height: calc((100dvh - 3.5rem - 10px) / 2);
      left: 5px;
      bottom: 5px;
    }

    .right {
      width: calc(50% - 10px);
      height: calc(100dvh - 3.5rem - 10px);
      right: 5px;
      top: 5px;
    }

    .top-right {
      width: calc(50% - 10px);
      height: calc((100dvh - 3.5rem) / 2);
      right: 5px;
      top: 5px;
    }

    .bottom-right {
      width: calc(50% - 10px);
      height: calc((100dvh - 3.5rem) / 2);
      right: 5px;
      bottom: 5px;
    }

  }
}