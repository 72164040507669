#desktop-body {
    padding: .25rem;
    background-color: transparent;
    position: relative;
    width: 100%;
    height: 100%;

    .icons-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, 100px);
        /* larghezza fissa dei bottoni */
        grid-auto-rows: 100px;
        /* altezza fissa dei bottoni */
        gap: 1rem;
        /* distanza orizzontale e verticale tra i bottoni */
        justify-content: start;
        /* allinea a sinistra come Windows */
        align-content: start;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;



        .desktop-icon {
            width: 100px;
            height: 100px;
            border-radius: .5rem;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 0px;
            margin: 1rem;
            background-color: rgba(var(--interactive-rgb-light), .3);
            color: white; //var(--interactive-light-text);
            position: relative;

            &:hover {
                background-color: rgba(var(--interactive-rgb-light), .5);
                color: var(--interactive-light-text);
            }

            &:active {
                background-color: rgba(var(--interactive-rgb-light), .7);
                color: var(--interactive-light-text);
            }

            span {
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-word;
                text-overflow: ellipsis;
                display: -webkit-box;
                max-height: 50%;
            }
        }
    }

}


@media screen and (max-width:576px) {

    .icons-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, 100px);
        /* larghezza fissa dei bottoni */
        grid-auto-rows: 75px;

        .desktop-icon {
            width: 75px;
            height: 75px;
        }
    }
}